import React from 'react';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';

function VishalPreop() {
    return (
        <div className="container mt-5">
            <header>
                <h3 className="text-center">Patient's Journey</h3>
            </header>
            <div className="row mt-5">
                <div className="col-sm-12 col-md-12 mb-5">
                    <div className="card">
                        <ReactPlayer url='https://patient-portal.s3.fr-par.scw.cloud/website/videos/Vishal/vishal_intro.mp4' className="card-img-bottom" width='100%' height='300px' controls={true}/>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-sm-12 col-md-12">
                                    <b>A patient's journey on the day of surgery</b>
                                    <ul>
                                        <li className="pb-2">
                                            The surgical team takes over your care once you are booked on the system.  
                                            About 2 weeks before the surgery you will be contacted from our patient liason officer to discuss your medical history. 
                                            This is to make sure you are fit for surgery and to prepare yourself for the day of surgery.
                                        </li>
                                        <li className="pb-2">
                                            If the officer comes across any medical issues during this point of contact, the specialist will get involved. They can then call you to discuss and take measures to make your surgery safe and efficient.
                                        </li>
                                        <li className="pb-2">
                                            On the day of surgery you will arrive at 8 am. Once the paper works are done, you will be escorted to your surgical room.  You are allocated two technicians who will look after you throughout the day. You will be asked to sign a consent form on your surgery day. Please have a read through the consent form as it will speed up the process during your surgery date. <Link to="/patient-consent-form" className=''>Click me to view the consent form.</Link>
                                        </li>
                                        <li className="pb-2">
                                            A specialist will then meet you to discuss about the procedure. Later, they will design your hairline and mark the area that needs coverage taking into account your wishes and considerations.
                                        </li>
                                        <li className="pb-2">
                                            For the first half of the procedure you will be positioned facing down. The marked donor area is anaesthetised. It is a local anaesthesia, so  you will be awake through out the procedure. You will have some pain for the first 2 to 3 mins due to the injections. Once the anaesthesia is done the extraction of the hair takes place. 
                                            0.85 sized punches are used for extraction. However, in case of afro hair the size may need to be increased.
                                        </li>
                                        <li className="pb-2">
                                            Once the extraction is complete you will now change and rest facing up. Now the recipient area, the part which needs hair is anaesthetised. 
                                            You will be provided some light lunch in between.
                                        </li>
                                        <li className="pb-2">
                                            Afterwards, the specialist will create the holes for the hair to go in. This step is called <b>channel opening</b>. This is done using tiny blades that are measured according to the size of the graft. They are usually less than a mm in size. The holes are created following the directions of your existing hair which gives you a more natural look.
                                        </li>
                                        <li className="pb-2">
                                            After this, the hair is implanted one by one by our fully trained and experienced technicians.
                                        </li>
                                        <li className="pb-2">
                                            Once the implanting finishes, we will discuss with you about the necessary steps to be taken to look after the implanted hair.
                                        </li>
                                        <li className="pb-2">
                                            It is extremely important to follow the instructions carefully for the first two weeks following surgery.
                                        </li>
                                        <li className="pb-2">
                                            Before we finish for the day, the specialist will discuss with you in detail about how to enhance your results and look after your existing hair.
                                        </li>
                                        <li className="pb-2">
                                            Within the first few days following the surgery you will receive a call from our team to check your well-being. 
                                            You will be provided with a contact number to discuss any advice following surgery.
                                        </li>
                                        <li className="pb-2">
                                            The team will contact you at 6 months and then again at 12 months to check the progress. These follow-ups are important to monitor the progress of your hair growth.
                                            The hair growth starts at around 3 to 4 months’ time and it may take up to 15 to 18 months to get full results but this varies from person to person.
                                            </li>
                                        <li className="pb-2"> 
                                            We hope this information provides you with a good insight into a hair transplant procedure. If you have any further queries please do no hesitate to contact us. Have a good day.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VishalPreop
