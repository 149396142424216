import React, { useState, useEffect, useRef } from 'react';
import Navbar from '../components/Navbar/Navbar';
import StageNavbar from '../components/Stages/StageNavbar';
import { withRouter } from "react-router-dom";
import { clinic0, clinic1, clinic2, clinic3, clinic4, clinic5 } from '../components/Navbar/NavbarData';
import axiosInstance from '../axios';
import AlosertPostop from './Postop/AlosertPostop';
import DittaPostop from "./Postop/DittaPostop";
import HishamPostop from './Postop/HishamPostop';
import MunirPostop from "./Postop/MunirPostop";
import SamiPostop from "./Postop/SamiPostop";
import ShuaibPostop from "./Postop/ShuaibPostop";
import ShuaibKentonPostop from "./Postop/ShuaibKentonPostop";
import VishalPostop from "./Postop/VishalPostop";
import RafiqPostop from "./Postop/RafiqPostop";
import ContactUs from '../components/ContactUs';
import { Kenton, London, Manchester, Manchester2, Romford } from './BranchData.js';
import '../css/Instructions.css';

function Postop() {

    const [name, setName] = useState({name: '', logo: '', email: ''});
    const contactRef = useRef();
    const [postopInstructions, setpostopInstructions] = useState();
    const [branchData, setbranchData] = useState();
    const [doctorId, setDoctorId] = useState();

    useEffect(() => {
        axiosInstance.get('clinic-detail/').then((res) => {
            console.log(res);
            console.log(res.data);
            let data;
            //let clinic = res.data.clinic_name;
            let clinic = res.data.clinic_name;
            let doctorSelection = res.data.doctor_name;
            if(clinic === 1 && doctorSelection === 2){
                data = clinic0;
                setpostopInstructions(<AlosertPostop />);
                setbranchData(Kenton);
                setDoctorId("Dr Alosert");
            } else if(clinic === 3) {
                data = clinic1;
                setpostopInstructions(<HishamPostop />);
                setbranchData(Manchester);
                setDoctorId("Dr Hisham");
            } else if(clinic === 4) {
                data = clinic2;
                setpostopInstructions(<MunirPostop />);
                setbranchData(Manchester2);
                setDoctorId("Dr Munir");
            } else if(clinic === 1 && doctorSelection === 3) {
                data = clinic3;
                setpostopInstructions(<SamiPostop />);
                setbranchData(Kenton);
                setDoctorId("Dr Sami");
            } else if(clinic === 0 && doctorSelection === 0) {
                data = clinic4;
                setpostopInstructions(<ShuaibPostop />);
                setbranchData(London);
                setDoctorId("Dr Shuaib");
            } else if(clinic === 1 && doctorSelection === 0) {
                data = clinic4;
                setpostopInstructions(<ShuaibKentonPostop />);
                setbranchData(Kenton);
                setDoctorId("Dr Shuaib");
            } else if(clinic === 2) {
                data = clinic5;
                setpostopInstructions(<VishalPostop />);
                setbranchData(Romford);
                setDoctorId("Dr Vishal");
            } else if(clinic === 0 && doctorSelection === 1) {
                data = clinic4;
                setpostopInstructions(<RafiqPostop />);
                setbranchData(London);
                setDoctorId("Dr Rafiq");
            }

            setName({ name: data.name, logo: data.logo, email: data.email, reference: contactRef });
        });
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <Navbar {...name} /><hr className="m-0 p-0" />
            <StageNavbar reference={contactRef} doctorId={doctorId} /><hr className="m-0 p-0" />
            { postopInstructions }
            <ContactUs reference={contactRef} branchData={branchData} doctorId={doctorId} />
        </div>
    )
}

export default withRouter(Postop);
