import React from 'react';
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import '../../css/Doctors.css';

function DrSami() {
    return (
        <div className="container doctor-profile-page mt-5">
            <h2 className="doctor-profile-page-name">Dr Sami Mirza</h2>
            <img src="https://patient-portal.s3.fr-par.scw.cloud/website/images/Doctors/DrSamic.png" alt="Dr Sami" className="float-sm-end" style={{height:'300px'}} />
            <ul className="doctor-list-style-none p-0">
                <li><IoMdCheckmarkCircleOutline size="20px" color="#191667" />  Vast experience in the hair transplant industry. Highly sought-after surgeon having worked on many celebrity clientele. Having qualified over 12 years ago, he has attained breadth of surgical experience in many disciplines prior to specialising in hair restoration surgery. He has worked with many of the worlds leading hair transplant surgeons, having performed over 1500 successful procedures.</li>
				<br/>
				<li><IoMdCheckmarkCircleOutline size="20px" color="#191667" />  Known for his keen attention to detail and caring attitude towards his patients, Dr Sami always aims to take a holistic approach and provide a complete package of care throughout the patient journey.</li>
				<div className="mt-1">
                    <h3 className="doctor-profile-page-name mt-3">Dr Sami specialises in:</h3>
                    <ul className="doctor-list-style-none p-0">
						<li><IoMdCheckmarkCircleOutline size="20px" color="#191667" /> Advanced & modern FUE methodology.</li>
						<li><IoMdCheckmarkCircleOutline size="20px" color="#191667" /> Quality driven approach, an aesthetical connoisseur.</li>
						<li><IoMdCheckmarkCircleOutline size="20px" color="#191667" /> Wealth of experience in beard and Afro hair transplants, including coverage for scarred areas.</li>
						<li><IoMdCheckmarkCircleOutline size="20px" color="#191667" /> Minimally invasive hair and skin aesthetic surgery.</li>
						<li><IoMdCheckmarkCircleOutline size="20px" color="#191667" /> Breadth of experience in multiple hair restoration techniques.
							<ul><li className="pb-0"> Including Platelet-Rich Plasma, Growth Factor Concentrate and Mesotherapy.</li></ul>
						</li>
						<li><IoMdCheckmarkCircleOutline size="20px" color="#191667" /> Devises tailored patient hair restorative & hair loss prevention treatment programmes.</li>
						<li><IoMdCheckmarkCircleOutline size="20px" color="#191667" /> High density hairline, temple, and crown restoration.</li>
						<li><IoMdCheckmarkCircleOutline size="20px" color="#191667" /> Patient-centred and caring attitude in his approach.</li>
					</ul>
                </div>
				<div className="mt-1">
                    <h3 className="doctor-profile-page-name mt-3">Other interests:</h3>
                    <ul className="doctor-list-style-none p-0">
                        <li><IoMdCheckmarkCircleOutline size="20px" color="#191667" />  Corticosteroid joint injection therapy.</li>
                        <li><IoMdCheckmarkCircleOutline size="20px" color="#191667" />  Keloid scar reduction therapy.</li>
                        <li><IoMdCheckmarkCircleOutline size="20px" color="#191667" />  Additional qualifications in Neuroscience and Neurophysiology.</li>
                    </ul>
                </div>
            </ul>
        </div>
    );
}

export default DrSami
