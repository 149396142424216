export const clinic0 = {
    name: 'Hair Transplant',
    logo: 'images/my_hairtransplant_clinic.png',
    email: ''
};
  
export const clinic1 = {
    name: 'Medical Transplant',
    logo: 'images/my_hairtransplant_clinic.png',
    email: ''
};

export const clinic2 = {
    name: 'Hair transplant',
    logo: 'images/my_hairtransplant_clinic.png',
    email: ''
};

export const clinic3 = {
    name: 'Hair transplant',
    logo: 'images/my_hairtransplant_clinic.png',
    email: ''
};

export const clinic4 = {
    name: 'Hair transplant',
    logo: 'images/my_hairtransplant_clinic.png',
    email: ''
};

export const clinic5 = {
    name: 'Hair transplant',
    logo: 'images/my_hairtransplant_clinic.png',
    email: ''
};