import React, { useState, useEffect, useRef } from 'react';
import Navbar from '../components/Navbar/Navbar';
import StageNavbar from '../components/Stages/StageNavbar';
import { clinic0, clinic1, clinic2, clinic3, clinic4, clinic5 } from '../components/Navbar/NavbarData';
import axiosInstance from '../axios';
import ContactUs from '../components/ContactUs';
import { Kenton, London, Manchester, Manchester2, Romford } from './BranchData.js';
import { Link } from 'react-router-dom';

function Faq() {
    const [name, setName] = useState({name: '', logo: '', email: ''});
    const contactRef = useRef();
    const [branchData, setbranchData] = useState();
    const [doctorId, setDoctorId] = useState();
    const [userAccount, setUserAccount] = useState();
    const [complaintsPolicyLink, setComplaintsPolicyLink] = useState();

    useEffect(() => {
        axiosInstance.get('clinic-detail/').then((res) => {
            console.log(res);
            console.log(res.data);
            let data;
            let clinic = res.data.clinic_name;
            let doctorSelection = res.data.doctor_name;
            setUserAccount(res.data.username);
            if(clinic === 1 && doctorSelection === 2){
                data = clinic0;
                setbranchData(Kenton);
                setDoctorId("Dr Alosert");
                setComplaintsPolicyLink('https://s3.eu-west-1.wasabisys.com/medical-checklist/media/files/2022/07/22/d3b59355-9732-46b3-bd4f-31179cf4c1f9.pdf?AWSAccessKeyId=514TT1YU9CA9QCBEI5MS&Signature=q6hc0Iowh8gqCJWtY%2FdoVo6EhXA%3D&Expires=1659401773');
            } else if(clinic === 3) {
                data = clinic1;
                setbranchData(Manchester);
                setDoctorId("Dr Ditta");
                setComplaintsPolicyLink('https://s3.eu-west-1.wasabisys.com/medical-checklist/media/files/2022/07/07/75cb4ea8-adc5-4c67-a02a-2010ecd20a7f.pdf?AWSAccessKeyId=514TT1YU9CA9QCBEI5MS&Signature=TqFv%2Bg59VofqfqtLb28IV881hA4%3D&Expires=1659401256');
            } else if(clinic === 4) {
                data = clinic2;
                setbranchData(Manchester2);
                setDoctorId("Dr Munir");
                setComplaintsPolicyLink('https://s3.eu-west-1.wasabisys.com/medical-checklist/media/files/2022/07/07/75cb4ea8-adc5-4c67-a02a-2010ecd20a7f.pdf?AWSAccessKeyId=514TT1YU9CA9QCBEI5MS&Signature=TqFv%2Bg59VofqfqtLb28IV881hA4%3D&Expires=1659401256');
            } else if(clinic === 1 && doctorSelection === 3) {
                data = clinic3;
                setbranchData(Kenton);
                setDoctorId("Dr Sami");
                setComplaintsPolicyLink('https://s3.eu-west-1.wasabisys.com/medical-checklist/media/files/2022/07/22/d3b59355-9732-46b3-bd4f-31179cf4c1f9.pdf?AWSAccessKeyId=514TT1YU9CA9QCBEI5MS&Signature=q6hc0Iowh8gqCJWtY%2FdoVo6EhXA%3D&Expires=1659401773');
            } else if(clinic === 0 && doctorSelection === 0) {
                data = clinic4;
                setbranchData(London);
                setDoctorId("Dr Shuaib");
                setComplaintsPolicyLink('https://s3.eu-west-1.wasabisys.com/medical-checklist/media/files/2022/07/22/d3b59355-9732-46b3-bd4f-31179cf4c1f9.pdf?AWSAccessKeyId=514TT1YU9CA9QCBEI5MS&Signature=q6hc0Iowh8gqCJWtY%2FdoVo6EhXA%3D&Expires=1659401773');
            } else if(clinic === 2) {
                data = clinic5;
                setbranchData(Romford);
                setDoctorId("Dr Vishal");
                setComplaintsPolicyLink('https://s3.eu-west-1.wasabisys.com/medical-checklist/media/files/2022/07/07/75cb4ea8-adc5-4c67-a02a-2010ecd20a7f.pdf?AWSAccessKeyId=514TT1YU9CA9QCBEI5MS&Signature=SQ1zBAphzSRYKuYDj6ZKcym5XYY%3D&Expires=1659401861');
            } else if(clinic === 0 && doctorSelection === 1) {
                data = clinic4;
                setbranchData(London);
                setDoctorId("Dr Rafiq");
                setComplaintsPolicyLink('https://s3.eu-west-1.wasabisys.com/medical-checklist/media/files/2022/08/02/d663a697-06f0-4568-a624-daaf1fae5096.pdf?AWSAccessKeyId=514TT1YU9CA9QCBEI5MS&Signature=Zs%2Bq1bdVzEZqMHKx1RLCJNUU5gc%3D&Expires=1659403770');
            }

            setName({ name: data.name, logo: data.logo, email: data.email, reference: contactRef });
        });
    }, []);

    return (
        <div>
            <Navbar {...name} /><hr className="m-0 p-0" />
            <StageNavbar reference={contactRef} doctorId={doctorId} /><hr className="m-0 p-0" />
            <div className="my-5">
                <header>
                    <h3 className="text-center">FAQ</h3>
                </header>
                <div className="container mt-5">
                    <div className="accordion accordion-flush" id="accordionFlushExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingOne">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                How long does the surgery last?
                            </button>
                            </h2>
                            <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    Typically will last 8-9 hours depending on a number of factors. The surgery may be shorter, however it rarely lasts longer.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingTwo">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                Is it a painful procedure?
                            </button>
                            </h2>
                            <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    The local anaesthetic injections given initially in the donor area and recipient area sting and hurt a little for the first few minutes. The procedure is typically pain-free following this.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingThree">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                What is a FUE?
                            </button>
                            </h2>
                            <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    <b>Follicle Unit Extraction:</b>
                                    <ul>
                                        <li>It is the most recent up-to-date hair transplant technique</li>
                                        <li>It is less invasive than FUT [FU strip]</li>
                                        <li>FUE is a painless process after receiving the local anaesthetics.</li>
                                        <li>FUE leaves no visible linear scarring. </li>
                                        <li>FUE leaves only tiny, dot-like scars which are virtually impossible to see, dots of less than a 1 mm in the donor area.</li>
                                        <li>FUE is: Collecting individual hair follicles from the donor area and implant them into the newly created non visible tiny incisions in the recipient area [hair redistribution]</li>
                                        <li>No sutures required</li>
                                        <li>Swelling is only temporary and will resolve completely in few days. </li>
                                        <li>No long off time or long down time required, recovery in 1-2 weeks</li>
                                        <li>Back to usual social / professional life soon after the surgery.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingFour">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                What do I do if my scabs are not coming off?
                            </button>
                            </h2>
                            <div id="flush-collapseFour" className="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    From Day 10 you can gently tap the recipient area when applying the baby shampoo mixture. If the scabs do not come off by Day 14 – leave the shampoo mixture to soak on for 60 minutes or more and gently tap. Then you may rinse it off. 
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingFive">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                                Can I wear a hat or cap?
                            </button>
                            </h2>
                            <div id="flush-collapseFive" className="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    NO headwear allowed for the first 14 days. After this you may wear a hat. If you wear a hard hat or helmet then you can wear this after 14 days but it is recommended to wear a soft hat underneath to cushion the recipient area. 
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingSix">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                                Why am I having discharge from my recipient area?
                            </button>
                            </h2>
                            <div id="flush-collapseSix" className="accordion-collapse collapse" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    It can be normal to have a slight yellowish-watery discharge from the transplanted zone. This usually settles within a few days. If you notice pus or thick discharge or have an offensive smell from this discharge – please contact the patient liaison manager.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading7">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse7" aria-expanded="false" aria-controls="flush-collapse7">
                                What if I do not want to shave all my hair?
                            </button>
                            </h2>
                            <div id="flush-collapse7" className="accordion-collapse collapse" aria-labelledby="flush-heading7" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    We can provide unshaven technique [depends on the density required as shaven technique will provide better density cover, gain higher number of transplanted grafts in the recipient area]
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading8">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse8" aria-expanded="false" aria-controls="flush-collapse8">
                                Why is my transplanted hair falling out?
                            </button>
                            </h2>
                            <div id="flush-collapse8" className="accordion-collapse collapse" aria-labelledby="flush-heading8" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    This is called Shedding. It is part of the normal process following surgery and can occur within the first 12 weeks. It can be uneven and some areas can have more shedding than others. It is common and is essentially a normal process following surgery with most patients experiencing it. This does not indicate that the root has fallen.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading9">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse9" aria-expanded="false" aria-controls="flush-collapse9">
                                How many sessions/ surgeries do I need?
                            </button>
                            </h2>
                            <div id="flush-collapse9" className="accordion-collapse collapse" aria-labelledby="flush-heading9" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    Depends on your hair loss level, your target to reach, and your donor area status but classically 2 sessions required during lifetime.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading10">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse10" aria-expanded="false" aria-controls="flush-collapse10">
                                How long before I see my new hairs growing?
                            </button>
                            </h2>
                            <div id="flush-collapse10" className="accordion-collapse collapse" aria-labelledby="flush-heading10" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    Varies from person to another; Hair growth cycle is long, initially, hair shedding for 8 weeks, re-growing phase, 6 months to find about 50% of the new hairs. The final results is usually achieved by 12-18 months.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading11">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse11" aria-expanded="false" aria-controls="flush-collapse11">
                                Will FUE provide natural looking hairs?
                            </button>
                            </h2>
                            <div id="flush-collapse11" className="accordion-collapse collapse" aria-labelledby="flush-heading11" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    Our experienced surgeons have artistic surgical skills to create the most naturally looking incisions for the collected follicles to be implanted in.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading12">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse12" aria-expanded="false" aria-controls="flush-collapse12">
                                Am I going to have full head cover?
                            </button>
                            </h2>
                            <div id="flush-collapse12" className="accordion-collapse collapse" aria-labelledby="flush-heading12" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    Our skilled surgeon will do their best to distribute the maximally collected follicles in a wise way to provide the most naturally looking lifetime hairs. So the answer is: it depends how good/ rich the donor area and how big the recipient area.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading13">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse13" aria-expanded="false" aria-controls="flush-collapse13">
                                How many procedure/ sessions do I need?
                            </button>
                            </h2>
                            <div id="flush-collapse13" className="accordion-collapse collapse" aria-labelledby="flush-heading13" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                Depends on the donor area, how severe your hair loss and how is good the healing process in the donor area. If required, a 2nd procedure will be possible to reach better density [your hair surgeon is always ready to discuss that]
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading14">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse14" aria-expanded="false" aria-controls="flush-collapse14">
                                What do I expect after the FUE, possible events?
                            </button>
                            </h2>
                            <div id="flush-collapse14" className="accordion-collapse collapse" aria-labelledby="flush-heading14" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    <li>Possible scalp/ face swelling for few days [nothing serious: the headband and keeping the head elevated will help]</li>
                                    <li>Possible headache, scalp pain or irritation [nothing serious: take regular paracetamol for 2 days then as required]</li>
                                    <li>Possible Itchiness [nothing serious: take anti-histamine like piriton & spray the head with saline/ mineral water] but Do Not Itch.</li>
                                    <li>Possible dryness and excessive scabbing [nothing serious: need frequent head wash, use saline spray and keep good oral water intake][will be scabbing free by day 14]</li>
                                    <li>Numbness [nothing serious: recovery is quite individual and may take from few day to several weeks but still less than 6 months of FUT ]</li>
                                    <li>Shedding [nothing serious: will follow the natural hair cycle and will be for 6-8 weeks before the new hair re-regrow again]</li>
                                    <li>Expect to see 50-60 % of new hair growth by 6-8 months [final results will be seen by 12-18 months]</li>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading15">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse15" aria-expanded="false" aria-controls="flush-collapse15">
                                Why do I have spots on the donor area?
                            </button>
                            </h2>
                            <div id="flush-collapse15" className="accordion-collapse collapse" aria-labelledby="flush-heading15" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    This can occur post procedure and is usually a result of not exfoliating firmly enough. You can apply coconut oil or aloe vera gel from day 5 onwards and scrub firmly.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading16">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse16" aria-expanded="false" aria-controls="flush-collapse16">
                                Why do I have an odd sensation on my head (numbing, tingling)?
                            </button>
                            </h2>
                            <div id="flush-collapse16" className="accordion-collapse collapse" aria-labelledby="flush-heading16" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    This can be expected given the nature of the process. This tends to settle within a few months. If it is troublesome and persistent – please feel free to contact the patient liaison manager.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading17">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse17" aria-expanded="false" aria-controls="flush-collapse17">
                                Can I take painkillers?
                            </button>
                            </h2>
                            <div id="flush-collapse17" className="accordion-collapse collapse" aria-labelledby="flush-heading17" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    YES. But avoid Ibuprofen or Naproxen. It is safer to take Paracetamol or Co-codamol.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading18">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse18" aria-expanded="false" aria-controls="flush-collapse18">
                                When can I exercise?
                            </button>
                            </h2>
                            <div id="flush-collapse18" className="accordion-collapse collapse" aria-labelledby="flush-heading18" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    After 2 weeks you can resume strenuous activity.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading19">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse19" aria-expanded="false" aria-controls="flush-collapse19">
                                When can I leave the house?
                            </button>
                            </h2>
                            <div id="flush-collapse19" className="accordion-collapse collapse" aria-labelledby="flush-heading19" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    You may leave the house following the procedure but the same rules apply with regards to strenuous activity, headwear and sweating. The first 3 days are vitally important, so if you can rest indoors for 3 days – this is better. If you do leave the house, please ensure you have an umbrella if it is raining or sunny to avoid damage to the transplanted zone.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading20">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse20" aria-expanded="false" aria-controls="flush-collapse20">
                                Can I go back to work immediately?
                            </button>
                            </h2>
                            <div id="flush-collapse20" className="accordion-collapse collapse" aria-labelledby="flush-heading20" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    Ideally not. Rest is required to aid with the healing process. Two weeks is ideal to take off. If this is not possible, the longer the better. Depending on your type of work – the surgeon may advise needing 2 weeks off.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading21">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse21" aria-expanded="false" aria-controls="flush-collapse21">
                                Can I have numbing cream before the anaesthetic?
                            </button>
                            </h2>
                            <div id="flush-collapse21" className="accordion-collapse collapse" aria-labelledby="flush-heading21" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    Yes, but it is not very effective. The administration of the actual local anaesthetic causes the brief pain or sting. 
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading22">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse22" aria-expanded="false" aria-controls="flush-collapse22">
                                When can I have a shower?
                            </button>
                            </h2>
                            <div id="flush-collapse22" className="accordion-collapse collapse" aria-labelledby="flush-heading22" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    A full body shower/ (get head wet) is permitted after two weeks. Please refer to the washing regime.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading23">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse23" aria-expanded="false" aria-controls="flush-collapse23">
                                What do I need to have at home before my surgery?
                            </button>
                            </h2>
                            <div id="flush-collapse23" className="accordion-collapse collapse" aria-labelledby="flush-heading23" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    The things that are needed: Travel pillow, anti-histamine tablets, paracetamol, baby shampoo and also coconut oil. 
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading24">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse24" aria-expanded="false" aria-controls="flush-collapse24">
                                How much recovery time is needed?
                            </button>
                            </h2>
                            <div id="flush-collapse24" className="accordion-collapse collapse" aria-labelledby="flush-heading24" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    The recommended recovery time is usually two weeks, unless your surgeon advises differently.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading25">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse25" aria-expanded="false" aria-controls="flush-collapse25">
                                Will the result leave a natural look?
                            </button>
                            </h2>
                            <div id="flush-collapse25" className="accordion-collapse collapse" aria-labelledby="flush-heading25" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    The skills and techniques used by our surgeons ensure you are left with a natural looking result. Please bear in mind this can take months to develop.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading26">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse26" aria-expanded="false" aria-controls="flush-collapse26">
                                When will I start to see a result?
                            </button>
                            </h2>
                            <div id="flush-collapse26" className="accordion-collapse collapse" aria-labelledby="flush-heading26" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    You may see some hair growth within the first 2 weeks. You will go through different stages of the hair growth cycle, including shedding. The final result is obtained between 12-18 months. 
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading27">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse27" aria-expanded="false" aria-controls="flush-collapse26">
                                Overnight / Out of hours emergency
                            </button>
                            </h2>
                            <div id="flush-collapse27" className="accordion-collapse collapse" aria-labelledby="flush-heading27" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    In the case of any emergency, the patient is advised to have a low thresold for self referal to a&e. This is the case for both in hours and out of hours.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading28">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse28" aria-expanded="false" aria-controls="flush-collapse26">
                                How to make a complaint
                            </button>
                            </h2>
                            <div id="flush-collapse28" className="accordion-collapse collapse" aria-labelledby="flush-heading28" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    <b>Identifying and Receiving Complaints</b>
                                    <ul>
                                        <li>Patients may raise concerns to any member of staff, verbally or in writing.</li>
                                        <li>They will be directed to the Practice Manager or Complaints Lead to hear them, or to address a written concern.</li>
                                        <li>In all cases an acknowledgement will be raised in writing and provided to the complainant within three working days, together with a copy of the Complaints Procedure if this has not been obtained before.</li>
                                        <li>The reply will give an estimate of the time required to investigate the complaint and reply again, which would normally be within ten working days and no later than twenty days.</li>
                                        <li>A written response, including the result of investigation, will be issued to the Patient at that time. If this is not possible, the Patient will be informed in writing why, and a new time frame issued.</li>
                                    </ul>
                                    You can contact us using the contact us form located at the bottom of the page.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ContactUs reference={contactRef} branchData={branchData} doctorId={doctorId} />
        </div>
    )
}

export default Faq
