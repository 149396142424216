import React from 'react';
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import '../../css/Doctors.css';

function DrHisham() {
    return (
        <div className="container doctor-profile-page my-5 pb-5">
            <h2 className="doctor-profile-page-name text-nowrap">Dr Hisham Band</h2>
            <img src="https://s3.eu-central-1.wasabisys.com/patient-portal/dr_images/hisham.png" alt="Dr Hisham" className="float-sm-end" style={{height:'300px'}} />
            <p><IoMdCheckmarkCircleOutline size="20px" color="#191667" /> Dr. Hisham Band is a distinguished Hair Transplant Surgeon renowned for his extensive experience in both the aesthetic and reconstructive aspects of surgery. His expertise extends beyond just hair transplant procedures, encompassing a wide range of cosmetic surgeries. He is particularly skilled in the art of follicular unit extraction (FUE), offering both shaven and unshaven options to cater to diverse patient needs.</p>
            <p><IoMdCheckmarkCircleOutline size="20px" color="#191667" /> Dr. Band's academic background is equally impressive. He has completed a Masters in Burns, Plastic, and Reconstructive Surgery from University College London (UCL), a testament to his dedication to advancing his skills and knowledge. His career path includes formal training in both NHS and private sectors, specializing in reconstructive surgery. This extensive training has honed his surgical precision and deepened his understanding of aesthetics. His commitment to patient transformation is not just limited to surgical procedures; he is actively involved in research and continuously seeks to evolve his techniques.</p>
            <p><IoMdCheckmarkCircleOutline size="20px" color="#191667" /> Dr. Band's career is marked by numerous medical and surgical accolades, including research awards and distinctions, highlighting his contribution to the field and his commitment to excellence in patient care.</p>
        </div>
    );
}

export default DrHisham
