import React from 'react';
import ReactPlayer from 'react-player';
import { TiTick, TiTimes} from "react-icons/ti";
import { Link } from 'react-router-dom';

function SamiPreop() {
    return (
        <div className="container mt-5">
            <header>
                <h3 className="text-center">Pre-op Instructions</h3>
            </header>
            <div className="row mt-5">
                
                <div className="col-sm-12 col-md-12 mb-5">
                    <div className="card">
                        <ReactPlayer url='https://patient-portal.s3.fr-par.scw.cloud/website/videos/Sami/preop.mp4' className="card-img-bottom" width='100%' height='300px' controls={true}/>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-sm-12 col-md-12">
                                    <b><u>TIMING</u></b>
                                    <p>The procedure takes anywhere between 6 – 9 hours</p>
                                    <b><u>WASH</u></b>
                                    <p><TiTick color="green" /> You may wash as normal on the day of surgery</p>
                                    <b><u>HAIRCUT</u></b>
                                    <p>
                                        <TiTimes color="red" /> So not cut or shave your hair
                                        <br/>
                                        <TiTimes color="red" /> So not apply any hair products
                                    </p>
                                    <b><u>FOOD / DRINKS / SMOKING</u></b>
                                    <p>
                                        <TiTick color="green" /> Be sure to eat a light starch braekfast (cereal or toast), we will be providing you with light lunch on the day.
										<br/>
                                        <TiTimes color="red" /> Please abstain from alcohol / smoking / caffeinated drinks / spicy foods for at least 24 hours prior to surgery.
                                    </p>
                                    <b><u>CLOTHING</u></b>
                                    <p><TiTick color="green" /> Wear buttoned or zipped top</p>
                                    <b><u>MEDICAL CONDITIONS</u></b>
                                    <p><TiTick color="green" /> Declare all previous and current medical conditions</p>
                                    <b><u>MEDICATIONS</u></b>
                                    <p>
                                        <TiTick color="green" /> Take blood pressure medications as usual
                                        <br/>
                                        <TiTimes color="red" /> Stop aspirin or other bloodthinners for 7 days with written permission from your specialist
                                        <br/>
                                        <TiTimes color="red" /> Stop multivitamins for 2 days
                                        <br/>
                                        <TiTimes color="red" /> Stop ibuprofen / diclofenac / naproxen for 2 days
                                    </p>
                                    <b><u>NO CAFFEINE</u></b>
                                    <p><TiTimes color="red" /> No tea/coffee/energy drinks</p>
                                    <b><u>DRIVING</u></b>
                                    <p><TiTimes color="red" /> Advised not to drive to the surgery</p>
                                    <b><u>WORK</u></b>
                                    <p><TiTick color="green" /> Take at least 7 days off to recover postop</p>
                                    <b><u>CONSENT FORM</u></b>
                                    <p><TiTick color="green" /> You will be asked to sign a consent form on your surgery day. Please have a read through the consent form as it will speed up the process during your surgery date. <Link to="/patient-consent-form" className=''>Click me to view the consent form.</Link></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SamiPreop
