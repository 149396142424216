import React, {useState, useEffect} from 'react';
import axiosInstance from '../axios';
import { useHistory } from 'react-router-dom';
import '../css/Login.css';

const Login = () => {

    const history = useHistory();
    const initialFormData = Object.freeze({
		username: '',
		password: '',
	});
    const [formData, setFormData] = useState(initialFormData);

    const handleChange = (e) => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value.trim(),
		});
	};

    const handleSubmit = (e) => {
		e.preventDefault();
		console.log(formData);
        let submitBtn = document.getElementById('login-btn');
        let domain_name = window.location.hostname.split('.').reverse()[1];
        let page_clip_form_key;
        switch (domain_name) {
            case "myhairtransplantlondon":
                page_clip_form_key = `xM5HCAo2T34YTtvNpbt3Mm2x5kDolgef`;
                /*if (formData.username !== 'MaidavaleHT') {
                    throw 'Incorrect username or password';
                }*/
                break;
            case "myhairtransplantkenton":
                page_clip_form_key = `2P4bvCVgVMG3nHXkfmcrTvyxBJuNrcjQ`;
                /*if (!(formData.username === 'myHair_DrA' || formData.username === 'myHair_DrS')) {
                    throw 'Incorrect username or password';
                }*/
                break;
            case "myhairtransplantmanchester":
                page_clip_form_key = `DbaKBHsyV0r0o5WqizjbOEhX1l4Lqc34`;
                /*if (formData.username !== 'myHair_DrD') {
                    throw 'Incorrect username or password';
                }*/
                break;
            case "myhairtransplantdeansgate":
                page_clip_form_key = `NH6SDkJLwMtub6ucAstG8VFOrR8QJTyZ`;
                /*if (formData.username !== 'myHair_DrM') {
                    throw 'Incorrect username or password';
                }*/
                break;
            case "myhairtransplantessex":
                page_clip_form_key = `LqNBinaPXSJ97rMig205yy1QeyB42bFn`;
                /*if (formData.username !== 'myHair_DrV') {
                    throw 'Incorrect username or password';
                }*/
                break;
            default:
                page_clip_form_key = null;
        }
        localStorage.setItem(`page_clip_form_key`, page_clip_form_key);
        let username = formData.username;
        console.log(username);
        if(username == 'myHair_DrSh'){
            console.log("dr shuaib login");
            localStorage.setItem(`formName`, 'patient_query_dr_shuaib');
        } else if(username == 'myHair_DrS'){
            console.log("dr sami login");
            localStorage.setItem(`formName`, 'patient_query_dr_sami');
        } else {
            console.log('any dr login');
            localStorage.setItem(`formName`, 'Patient-Query');
        }

		axiosInstance
			.post(`token/`, {
				username: formData.username,
				password: formData.password,
			})
			.then((res) => {
				localStorage.setItem('access_token', res.data.access);
				localStorage.setItem('refresh_token', res.data.refresh);
				axiosInstance.defaults.headers['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');
				history.push('/');
				console.log(res);
				console.log(res.data);
			}).catch(function (error) {
                document.getElementById('error-message').innerHTML = "Incorrect username or password";
                submitBtn.innerHTML = "Try again";
                submitBtn.style.backgroundColor = 'red';
            });
	};

    useEffect(() => {
        if(localStorage.getItem('access_token')){
            history.push('/');
        }
    }, []);

    return (
        <div id="form-section">
            <main className="form-signin">
                <form onSubmit={handleSubmit}>
                    <h1 className="h3 mb-3 fw-normal">Please sign in</h1>

                    <div className="form-floating">
                        <input type="text" id="username" name="username" className="form-control" onChange={handleChange} placeholder="Username" />
                        <label htmlFor="username">Username</label>
                    </div>
                    <div className="form-floating mt-2">
                        <input type="password" id="password" name="password" className="form-control" onChange={handleChange} placeholder="Password" />
                        <label htmlFor="password">Password</label>
                    </div>
                    <div id="error-message" className="text-danger mb-2"></div>
                    <button id="login-btn" className="w-100 btn btn-lg btn-primary" type="submit">Sign in</button>
                </form>
            </main>
        </div>
    );
}

export default Login;