import React, { useRef } from 'react';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';

function HishamPreop() {
    const preopRef = useRef();
    const preopExpectRef = useRef();
    const consentFormRef = useRef();

    const handlePreop = () => preopRef.current.scrollIntoView({ behavior: 'smooth', block: "center" });
    const handlePreopExpect = () => preopExpectRef.current.scrollIntoView({ behavior: 'smooth', block: "center" });
    const handleConsentForm = () => consentFormRef.current.scrollIntoView({ behavior: 'smooth', block: "center" });
    return (
        <div className="row px-3 mt-5">
            <div className="col-md-4">
                <div className="border d-none d-md-block" style={{'position':'sticky', 'top':100}}>
                    <ul style={{'listStyle':'none'}}>
                        <li className="py-1">
                            <span className="sidebarNav" onClick={handlePreop}>
                                Pre-operative Instructions
                            </span>
                        </li>
                        <li className="py-1">
                            <span className="sidebarNav" onClick={handlePreopExpect}>
                                What To Expect On The Day Of The FUE Procedure
                            </span>
                        </li>
                        <li className="py-1">
                            <span className="sidebarNav" onClick={handleConsentForm}>
                                Consent Form
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="col-md-8">
                <div id="preop">
                    <header>
                        <h3 className="text-center">Pre-operative Instructions</h3>
                    </header>
                    <div className="row mt-5">
                        <div className="col-sm-12 col-md-12 mb-5">
                            <div className="card" ref={preopRef}>
                                <ReactPlayer url='https://s3.eu-central-1.wasabisys.com/patient-portal/videos/dr_hisham_band/hisham_preop' className="card-img-bottom" width='100%' height='450px' controls={true}/>
                                <div className="card-body">
                                    <p>
                                        Most of the following would have already been discussed with your Consultant (Trichologist) with whom you have spoken to prior to arranging the surgery.
                                        Below is a list of important things to note prior to your FUE procedure. 
                                    </p>
                                    <ul className="ditta-preop-instruction-list">
                                        <li><b>MEDICAL CONDITIONS</b><br/>It is important to declare all medical problems that you currently have or have had in the past. Some conditions can impact on the Surgery. Please discuss this with your Consultant beforehand. </li>
                                        <li><b>MEDICATION</b><br/>Take your regular medications as prescribed by your Doctor as normal. IF you are taking Aspirin or other blood thinners – these should have been stopped earlier (Please discuss this with your Consultant). These are usually stopped 5-7 days prior to the procedure. </li>
                                        <li><b>MULTIVITAMINS</b><br/>Do not use multivitamins & food supplements for at least 2 days before the surgery. </li>
                                        <li><b>FOOD/DIET</b><br/>Eat breakfast as normal BUT NO CAFFEINE (tea/coffee/energy drinks) on the day of surgery. </li>
                                        <li><b>SMOKING & ALCOHOL</b><br/>Do not smoke or drink for 3 days before the surgery. (This can impact on the outcome of the surgery) </li>
                                        <li><b>DRIVING</b><br/> You are able to drive yourself but it is advisable, if possible, to get dropped off. It is a long procedure which may tire you out at the end of the day. </li>
                                        <li><b>WORK ARRANGEMENTS</b><br/>Arrange time off work. At least 7 days. Please discuss this with your Consultant. You may need more time off work (14 days) depending on the nature of your job. </li>
                                        <li><b>HAIRCUT</b><br/>You do not need to cut or shave your hair. This will be done in the clinic on the day of surgery. (This does not apply to the unshaven technique) </li>
                                        <li><b>HAIR WASHING</b><br/>You may wash your hair on the Day of Surgery </li>
                                        <li><b>CLOTHING</b><br/>Wear a buttoned or zipped top. Post surgery – clothes should not be taken off over the head due to the risk of damaging the implanted areas. </li>
                                        <li><b>TIMING</b><br/>FUE (Follicular Unit Extraction) is usually a whole day procedure (lasting approximately 8-9 hours) </li>
                                        <li><b>CONTACT DETAILS</b><br/>Provide your Next of Kin with the Clinic contact details </li>
                                        <li>
                                            <b>THINGS TO BRING:</b>
                                            <ul>
                                            <li>Appropriate clothing as stated above.  </li>
                                            <li>Headphones – some patients prefer to listen to podcasts or music during the day. Each clinical room has a Wireless Tablet Device on which desired music or podcasts can be played.  </li>
                                            <li>Travel Pillow - if you are staying over in a hotel. Only need following surgery. </li>
                                            <li>Relevant paperwork – your documents provided by your Consultant/Trichologist </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="expectation">
                    <header>
                        <h3 className="text-center">What To Expect On The Day Of The FUE Procedure</h3>
                    </header>
                    <div className="row mt-5">
                        <div className="col-sm-12 col-md-12 mb-5">
                            <div className="card" ref={preopExpectRef}>
                                <ReactPlayer url='https://s3.eu-central-1.wasabisys.com/patient-portal/videos/dr_hisham_band/hisham_on_day.mp4' className="card-img-bottom" width='100%' height='450px' controls={true}/>
                                <div className="card-body">
                                    <ul>
                                        <li><b>ARRIVAL</b><br />Upon Arrival to the Clinic – Administrative processes will be completed such as Outstanding Balances, Checking Demographics etc. </li>
                                        <li><b>PRE-OPERATIVE MEDICAL CHECKS</b><br />The clinical team will complete the health questionnaire if it hasn’t already been done. Your Blood Pressure and Pulse will be checked.  </li>
                                        <li><b>SURGEON REVIEW</b><br />You will see the Surgeon who will double check the health questionnaire. The procedure will be discussed and the desired surgical areas will be marked. Your hairline will be designed at this stage.  </li>
                                        <li><b>HAIRCUT</b><br />You will then be taken through for a haircut. (Unless – you have opted for an unshaven technique – please discuss this with your Consultant prior to the surgery) </li>
                                        <li><b>SURGICAL THEATRE</b><br />After your haircut, you will be taken through to your surgical theatre room, where you will be throughout the day. </li>
                                        <li><b>PAIN</b><br />The process will start off with local anaesthetic injections in the Donor Area and later you will again have these injections in the Recipient area. This part of the process does hurt for a short while, usually a few minutes. Once the local anaesthetic injections have been administered – the procedure should be pain free. If you feel pain throughout the day – please inform the Clinical Team. </li>
                                        <li>
                                            <b>FUE SURGERY – There are 3 stages to the process:  </b>
                                            <ol>
                                                <li>Extraction from the Donor Area.  </li>
                                                <li>Surgical Incisions/Channel Formation.  </li>
                                                <li>Implantation.  </li>
                                            </ol>
                                            You will be informed of this through the day and explained things as the day proceeds. IF AT ANY TIME YOU HAVE ANY QUESTIONS PLEASE ASK AWAY – THE CLINICAL TEAM AND SURGEON ARE THERE TO ANSWER YOUR QUESTIONS AND ENSURE YOU ARE COMFORTABLE THROUGHOUT THE PROCEDURE. 
                                        </li>
                                        <li><b>AFTERCARE</b><br />If you have any aftercare procedures, e.g PRP, this will be done at this stage following the FUE surgery. </li>
                                        <li><b>LUNCH</b><br />Is provided by the Surgical team. You do not need to bring your own food.  </li>
                                        <li><b>POST OPERATIVE ADVICE / INSTRUCTIONS</b><br />A headband will be applied (unless only crown area has been covered or you have had an eyebrow or beard transplant). The clinical team & surgeon will go through the advice and key instructions for the next few weeks following the procedure. </li>                      
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="consent-form" ref={consentFormRef}>
                    <header>
                        <h3 className="text-center">Consent Form</h3>
                    </header>
                    <div className="row mt-5">
                        <div className="col-sm-12 col-md-12 mb-5">
                            <div className="card">
                                <div className="card-body">
                                    <p>You will be asked to sign a consent form on your surgery day. Please have a read through the consent form as it will speed up the process during your surgery date.</p>
                                    <Link to="/patient-consent-form" className=''>Click me to view the consent form.</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HishamPreop
