import React, { useState } from 'react';
import { Button } from '../Button/Button';
import { Link } from 'react-router-dom';
import './Navbar.css';
import { FaBars, FaTimes } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';

function Navbar({name, logo, email, reference}) {
    const [click, setClick] = useState(false);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);


    function scrollToEnquiry(){
        closeMobileMenu();
        //reference.current.scrollIntoView({ behavior: 'smooth' });
        window.scroll({
            top: reference.current.offsetTop,
            behavior: 'smooth'
        });
    }


    return (
        <>
        <IconContext.Provider value={{ color: '#fff' }}>
            <nav className='main-navbar'>
            <div className='pe-5'>
                <Link to='/' className='main-navbar-logo' onClick={closeMobileMenu}></Link>
                <div className='menu-icon' onClick={handleClick}>
                {click ? <FaTimes /> : <FaBars />}
                </div>
                <ul className={click ? 'main-nav-menu active' : 'main-nav-menu'}>
                    <li className='main-nav-item'>
                        <Link to="" className='main-nav-links' onClick={scrollToEnquiry}>
                            Contact us
                        </Link>
                    </li>
                    <li className='main-nav-btn'>
                        <Link to='/logout' className='btn-link'>
                            <Button buttonStyle='btn--outline' onClick={closeMobileMenu}>Log out</Button>
                        </Link>
                    </li>
                </ul>
            </div>
            </nav>
        </IconContext.Provider>
        </>
    );
}

export default Navbar;