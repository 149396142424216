import React, { useState, useEffect } from 'react';
import './ImageGallery.css';

const ImageGallery = () => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [shuffledImages, setShuffledImages] = useState([]);
    const interval = 3000;
    const images = [
        // '/images/image1.jpg',
        // '/images/image2.jpg',
        // '/images/image3.jpg',
        // '/images/image4.jpg',
        // '/images/image5.jpg',
        // '/images/image6.jpg',
        // '/images/image7.jpg',
        // '/images/image8.jpg',
        // '/images/image9.jpg',
        // '/images/image10.jpg',
        // '/images/image11.jpg',
        // '/images/image12.jpg',
        // '/images/image13.jpg',
        // '/images/image14.jpg',
        'https://s3.eu-central-1.wasabisys.com/patient-portal/twentyoneagainImages/image3',
        'https://s3.eu-central-1.wasabisys.com/patient-portal/twentyoneagainImages/image5',
        'https://s3.eu-central-1.wasabisys.com/patient-portal/twentyoneagainImages/image6',
        'https://s3.eu-central-1.wasabisys.com/patient-portal/twentyoneagainImages/image7',
        'https://s3.eu-central-1.wasabisys.com/patient-portal/twentyoneagainImages/image8',
    ];

    useEffect(() => {
        const shuffleArray = (array) => {
            for (let i = array.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [array[i], array[j]] = [array[j], array[i]];
            }
            return array;
        };

        const shuffled = shuffleArray([...images]);
        setShuffledImages(shuffled);

        setCurrentImageIndex(0); // Reset to first image after shuffling

        const intervalId = setInterval(() => {
            setCurrentImageIndex(prevIndex => (prevIndex + 1) % shuffled.length);
        }, interval);

        return () => clearInterval(intervalId);  // Cleanup interval on component unmount
    }, [interval]); // Run only once on mount

    function handleClick(){
        // reference.current.scrollIntoView({ behavior: 'smooth' })
        window.location.href = 'https://twentyoneagain.com/'
    }

    return (
        <div className="image-gallery bannerimage-container mb-5 cursor-pointer" onClick={handleClick}>
            {shuffledImages.length > 0 && (
                <img src={shuffledImages[currentImageIndex]} alt={`slide-${currentImageIndex}`} className="img-fluid" />
            )}
        </div>
    );
};

export default ImageGallery;
