import React, { useEffect, useState } from 'react';
import { linkZero, linkOne, linkTwo, linkThree, linkFour } from './LinksData';
import { Link } from 'react-router-dom';
import StageLinks from './StageLinks';

const StageNavbar = ({reference, doctorId, username}) => {

    function handleClick(){
        reference.current.scrollIntoView({ behavior: 'smooth' });
    }

    const [covidText, setcovidText] = useState();
    const [patientSurveyLink, setPatientSurveyLink] = useState();

    useEffect(()=>{
        if(doctorId === "Dr Alosert" || doctorId === "Dr Sami"){
            setcovidText('If you received or planning to receive the Covid-19 vaccine, it is highly recommended to re-book / re-schedule your hair surgery at least 4 weeks before/after the Covid-19 vaccination.');
            setPatientSurveyLink('https://forms.gle/UY1Vcuxdi4Mtwtpf8');
        } else {
            setcovidText('If you received or planning to receive the Covid-19 vaccine, it is highly recommended to re-book / re-schedule your hair surgery at least 2 weeks before/after the Covid-19 vaccination.');
        }

        if(doctorId === "Dr Ditta"){
            setPatientSurveyLink('https://forms.gle/W5LD88ZDS8LNRfUP7')
        } else if(doctorId === "Dr Munir"){
            setPatientSurveyLink('https://forms.gle/QU5rtVZuTqW6kdG17')
        } else if(doctorId === "Dr Shuaib" || doctorId === "Dr Rafiq"){
            setPatientSurveyLink('https://forms.gle/JpdbnRXawkxcVmaN7');
        } else if(doctorId === "Dr Vishal"){
            setPatientSurveyLink('https://forms.gle/2odvkxJbghqdpoN77')
        }

    }, [doctorId]);

    return (
        <div>
            <header className="navbar navbar-light navbar-expand-md p-0">
                <div className="ps-5 w-100">
                    <ul className="nav w-100">
                        <ul className="navbar-nav flex-column flex-md-row w-100 justify-content-md-between align-items-center">
                            <div className='d-flex flex-column flex-md-row'>
                                <StageLinks {...linkZero} />
                                <StageLinks {...linkOne} />
                                <StageLinks {...linkTwo} />
                                <StageLinks {...linkThree} />
                                <StageLinks {...linkFour} />
                                
                                <li className="nav-item me-2">
                                    <Link to="" className="nav-link" onClick={handleClick}>
                                        <span className="nav-link-icon me-1 d-none d-md-inline-block"></span>Contact us
                                    </Link>
                                </li>
                            </div>
                            <li className="nav-item" style={{'backgroundColor':'#04AA6D', 'color': '#FFFFFF', 'border-color': '#04AA6D'}}>
                                <Link to={{ pathname: patientSurveyLink }} className="nav-link text-white" target="_blank">
                                    <span className="nav-link-icon me-1 d-none d-md-inline-block text-white"></span>Leave us your feedback
                                </Link>
                            </li>
                        </ul>
                    </ul>
                </div>
            </header>
            {/* <div className="d-flex flex-row justify-content-center text-center" style={{backgroundColor:'#191667', color:'white'}}>{covidText}</div> */}
            {/* <div className="d-flex flex-row justify-content-center text-center" style={{backgroundColor:'#191667', color:'white'}}>If you received or planning to receive the Covid-19 vaccine, it is highly recommended to re-book / re-schedule your hair surgery at least 14 days before/after the Covid-19 vaccination.</div> */}
        </div>
    )
}

export default StageNavbar
