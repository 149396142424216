import React from 'react';
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import '../../css/Doctors.css';

function DrVishal() {
    return (
        <div className="container doctor-profile-page mt-5">
            <h2 className="doctor-profile-page-name">Dr Vishal Nayak</h2>
            <img src="https://patient-portal.s3.fr-par.scw.cloud/website/images/Doctors/DrVc.png" alt="Dr Vishal" className="float-sm-end" style={{height:'300px'}} />
            <ul className="doctor-list-style-none p-0">
                <li><IoMdCheckmarkCircleOutline size="20px" color="#191667" />  Worked for over 7 years in the hair transplant industry.</li>
                <li><IoMdCheckmarkCircleOutline size="20px" color="#191667" />  Over 2000 successful patients.</li>
                <li><IoMdCheckmarkCircleOutline size="20px" color="#191667" />  Specialises in Afro Caribbean and Body hair extraction.</li>
                <li><IoMdCheckmarkCircleOutline size="20px" color="#191667" />  Wealth of experience in multiple hair restoration techniques.
                    <ul>
                        <li>FUE/non shaven hair transplant technique</li>
                        <li>Growth Factor Concentrate</li>
                        <li>Platelet-Rich Plasma</li>
                        <li>Mesotherapy</li>
                    </ul>
                </li>
                <li><IoMdCheckmarkCircleOutline size="20px" color="#191667" />  Highly sought after hair transplant surgeon.</li>
                <li><IoMdCheckmarkCircleOutline size="20px" color="#191667" />  Administered procedures on many celebrity clientele.</li>
                <li><IoMdCheckmarkCircleOutline size="20px" color="#191667" />  Professional and Courteous mannerism where patient focus is the main driving force.</li>
                <li><IoMdCheckmarkCircleOutline size="20px" color="#191667" />  Extensive portfolio of successful results – Male and Female.</li>
                <li><IoMdCheckmarkCircleOutline size="20px" color="#191667" />  Bilingual.</li>
                <li><IoMdCheckmarkCircleOutline size="20px" color="#191667" />  Dr Nayak is a very well established Dr with a wealth of experience in many of areas of expertise, his surgical pedigree is second to none in which the results speak for themselves.</li>
            </ul>
        </div>
    );
}

export default DrVishal
