import React from 'react';
import './Hero.css';
import { Button } from '../Button/Button';
import { Link } from 'react-router-dom';

function Hero({
  lightBg,
  topLine,
  lightText,
  lightTextDesc,
  headline,
  description,
  buttonLabel,
  img,
  alt,
  imgStart,
  docNo
}) {
  return (
    <div className={lightBg ? 'home__hero-section' : 'home__hero-section darkBg'}>
        <div className='container'>
            <div className='row home__hero-row' style={{ display: 'flex', flexDirection: imgStart === 'start' ? 'row-reverse' : 'row' }}>
                <div className='col-md-6 p-0'>
                    <div className='home__hero-text-wrapper py-3'>
                        <div className='top-line'>{topLine}</div>
                        <h1 className={lightText ? 'heading' : 'heading dark'}>
                            {headline}
                        </h1>
                        <p className={ lightTextDesc ? 'home__hero-subtitle' : 'home__hero-subtitle dark'}>
                            {description}
                        </p>
                        <Link to='/doctor' className="d-flex flex-column align-items-start">
                            <Button buttonSize='btn--wide' buttonColor='blue'>
                                {buttonLabel}
                            </Button>
                        </Link>
                    </div>
                </div>
                <div className='col-md-6 p-0'>
                    <div className='home__hero-img-wrapper ms-auto'>
                        <img src={img} alt={alt} className='home__hero-img' />
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default Hero;