import React from 'react';
import Form from './Form/Form';
function ContactUs({reference, branchData, doctorId}) {

    return (
        <div id="contact-us" ref={ reference } className="p-5 mb-3" style={{background: 'url("https://patient-portal.s3.fr-par.scw.cloud/website/images/footer-bg.jpg")', visibility: 'visible'}}>
            <h1 className="text-center text-white mb-5" style={{ fontWeight: "700" }}>Get in touch</h1>
            <Form {...branchData} doctorId={doctorId} />
            <div id="loading-modal" className="modal" tabIndex="-1">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div id="submission-result" className="modal-body text-center">
                            <i className="fas fa-spinner fa-spin fa-5x"></i>
                        </div>
                        <div className="modal-footer d-none">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
            {doctorId == "Dr Munir" ? 
                <div class="d-flex flex-column text-white mt-5">
                    <h6>Methods of communication and complaints</h6>
                    <p>
                    Regular communication with the medical team pre and post surgery or for local resolution of complaints is done via the following:</p>
                    <ul>
                        <li>​Patient liaison team email - <span class="text-primary">pt.liaison@myhairtransplantdeansgate.com</span></li>
                        <li>​Medical team access number - <span class="text-primary">07385298550</span></li>
                    </ul>
                    <p>Responses are usually on the day of contact but can take up to 3 working days depending on clinical need and availability of the medical team i.e. if contacted in work hours or out of hours(OOH) when the clinic is closed. If anything is urgent in an OOH setting patients are advised to seek medical review from their own Doctor/OOH GP or attend A&E.</p>
                
                    <p class="mt-5">
                        If patients have and complaints that can’t be dealt by the medical team they can escalate the matter via the following complaint email address to make a formal complaint - ​Complaint email - <span class="text-primary">enquiries@myhairtransplantdeansgate.com</span> <br/>
                        An initial response in acknowledgement of any complaint should be made within 72 hours of its receipt, at this stage it is purely an acknowledgement outlining the process and its commencement with an apology. Responses can take up to 25 working days to give time for full investigation of the complaint.
                    </p>

                    <p class="mt-5">
                        If the patient is wanting independent arbitration then the services of the Independent Doctors Federation can be utalised with whom all Dr’s working for Deansgate Surgery Limited are registered and the complaint is considered by the IDF Complaints Manager, seeking input from both parties to find a resolution or if this is not possible they can refer the matter on to ISCAS if the patient is still dissatisfied.
                        <a href="https://www.idf.co.uk/patients/patient-complaints.aspx">https://www.idf.co.uk/patients/patient-complaints.aspx</a>
                    </p>
                </div>
            :''}
        </div>
    );
}

export default ContactUs
