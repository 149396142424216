import React from 'react';
import { Link } from 'react-router-dom';

const StageLinks = ({link, icon, stageName}) => {

    return (
        <li className="nav-item me-2">
            <Link to={link} className="nav-link">
                <span className="nav-link-icon me-1 d-none d-md-inline-block">{ icon }</span>{ stageName }
            </Link>
        </li>
    )
}

export default StageLinks
