import React, { useRef } from 'react';
import ReactPlayer from 'react-player';
import ImageGallery from '../../components/PromoImageGallery/ImageGallery';

function VishalPostop() {

    const generalRef = useRef();
    const donorRef = useRef();
    const transplantedRef = useRef();
    const washingRef = useRef();
    const medicationRef = useRef();
    const complicationRef = useRef();


    const handleGeneral = () => generalRef.current.scrollIntoView({ behavior: 'smooth', block: "center" });
    const handleDonor = () => donorRef.current.scrollIntoView({ behavior: 'smooth', block: "center" });
    const handleTransplanted = () => transplantedRef.current.scrollIntoView({ behavior: 'smooth', block: "center" });
    const handleWashing = () => washingRef.current.scrollIntoView({ behavior: 'smooth', block: "center" });
    const handleMedication = () => medicationRef.current.scrollIntoView({ behavior: 'smooth', block: "center" });
    const handleComplication = () => complicationRef.current.scrollIntoView({ behavior: 'smooth', block: "center" });

    return (
        <div className="row px-3 mt-5">
            <div className="col-md-3">
                <div className='d-sm-block d-md-none border mb-5 pb-5'>
                    <ImageGallery />
                </div>
                <div className="border d-none d-md-block" style={{'position':'sticky', 'top':100}}>
                    <ul className='border-bottom py-3' style={{'listStyle':'none'}}>
                        <li className="py-1">
                            <span className="sidebarNav" onClick={handleGeneral}>
                                General Advice
                            </span>
                        </li>
                        <li className="py-1">
                            <span className="sidebarNav" onClick={handleDonor}>
                                Care Of Donor Area
                            </span>
                        </li>
                        <li className="py-1">
                            <span className="sidebarNav" onClick={handleTransplanted}>
                                Care Of Transplanted Area
                            </span>
                        </li>
                        <li className="py-1">
                            <span className="sidebarNav" onClick={handleWashing}>
                                Hair Washing Regimen
                            </span>
                        </li>
                        <li className="py-1">
                            <span className="sidebarNav" onClick={handleMedication}>
                                Medication Regimen
                            </span>
                        </li>
                        <li className="py-1">
                            <span className="sidebarNav" onClick={handleComplication}>
                                Complications That May Occur After Surgery
                            </span>
                        </li>
                    </ul>
                    <div className='mt-3'>
                        <ImageGallery />
                    </div>
                </div>
            </div>
            <div className="col-md-8">
                <div id="general">
                    <header>
                        <h3 className="text-center">POST OPERATIVE INSTRUCTIONS</h3>
                    </header>
                    <div className="row mt-5">
                        <div className="col-sm-12 col-md-12 mb-5">
                            <div className="card">
                                <ReactPlayer url='https://patient-portal.s3.fr-par.scw.cloud/website/videos/Vishal/postop.mp4' className="card-img-bottom" width='100%' height='450px' controls={true}/>
                                <div className="card-body" ref={ generalRef }>
                                    <h5><u>General Advice</u></h5>
                                    <ol >
                                        <li>Avoid touching the transplanted and donor areas until the day you start washing them (see below). Do not scratch the head at any time as it is important that germs are not transferred from your fingers / hands to the donor or recipient sites as this could cause infection. </li>
                                        <li>Keep the headband on for five days. Try and rest with your head at 30-degree elevation with the face pointing towards ceiling while resting. Ice can also be used on the forehead for first 24 hours to minimize the swelling. These measures are in place to minimize swelling to the forehead tracking down to the eyes, but it may still happen. However, swelling should eventually settle down in 5 days’ time. These measures are in place to minimize swelling to the forehead tracking down to the eyes. But make sure you don’t touch the grafted area.</li>
                                        <li>Eat a light meal (minimal chewing) following the procedure. Also avoid spicy food for 2 weeks.</li>
                                        <li>Sleep on your back and not on your stomach. </li>
                                        <li>If you are given steroid medication for the next few days to avoid swelling of the eyes and forehead, it is imperative that you do not add extra salt to your food over the next five days.</li>
                                        <li>Do not drink any alcoholic beverages the day following the procedure. Do not take any aspirin or any medication containing Salicylic Acid the day following your procedure. Also avoid the use of any non-prescribed substances for one week following the procedure. </li>
                                        <li>Wait at least one-week post-op before wearing anything (e.g.: Hat or Hoody) on the grafted area. If work requires wearing hard hat, avoid it for 2 weeks.</li>
                                        <li>Avoid strenuous exercise for 14 days following the procedure.</li>
                                        <li>Do not turn your head in an upside-down position on the day of the procedure or two days following the procedure.</li>
                                        <li>Do not expose your head to direct sunlight for 14 days following the procedure, we recommend you wear a hat/cap when going out into hot direct sunlight one week post procedure and avoid sunburn on the head for two months post procedure.</li>
                                        <li>Swimming: avoid the swimming pool for 2 months post procedure and 20 days post procedure swimming in the sea.  </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="washing">
                    <header>
                        <h3 className="text-center">Taking Care After Surgery</h3>
                    </header>
                    <div className="row mt-5">
                        <div className="col-sm-12 col-md-12 mb-5">
                            <div className="card">
                                <ReactPlayer url='https://patient-portal.s3.fr-par.scw.cloud/website/videos/Vishal/DrVwashing.mp4' className="card-img-bottom" width='100%' height='450px' controls={true}/>
                                <div className="card-body">
                                    <h5 ref={ donorRef }><u>Care Of Donor Area</u></h5>
                                    <p>
                                        On the second day morning, remove your headband and gently soak the bandage at the back of your head with lukewarm water. 
                                        This is to ensure it doesn’t stick to skin as you gently peel away. If at any time there is bleeding in either area, do not be concerned. 
                                        Apply direct pressure with a dry, clean cloth until the bleeding stops. This may take several minutes. 
                                        If you have any concerns or questions, please contact us. 
                                        Once this is completed leave the donor area open, so that it gets well aeriated and quickens the healing process. 
                                        Reapply your headband tightly. You may dry your hair with a warm, NOT HOT - hair dryer (only the donor area).
                                    </p>

                                    <h5 className="mt-3" ref={transplantedRef}><u>Care Of Transplanted Area</u></h5>
                                    <p>
                                        At the time of surgery, your scalp was thoroughly cleaned of blood and crusts, but these will tend to re-form soon after your procedure. Care should be taken while cleaning the transplanted site during the 10 days following surgery, because it is during this period that the healing mechanisms of your body secure the grafts firmly in place. However, appropriate care of the recipient area will minimize crusting and make the transplant less noticeable and the healing more rapid. 
                                        <br/>
                                        A spray bottle and a saline water solution are supplied on discharge. You may refill mineral water when the saline water provided is finished. You will need to spray the transplanted area every 20 minutes on the day of the surgery. You will need to spray vigorously, use both hands to ensure that you cover all the transplanted area. Do not touch the transplanted hair.
                                    </p>
                                    <p className="mt-2">
                                        On day 1 (day after the surgery) you should spray every 30 minutes during your waking hours and sleep as normal at night. Spraying prevents any scab formation on the transplanted area. The transplanted grafts also need to be kept hydrated throughout this period, this is essential for their survival. Continue spraying every 30 mins until your wash.
                                        <br/>
                                        The first wash to be performed on third day 3 following hair transplant. Mix baby shampoo and water (Half and Half) in a small container and pour it gently all over your head. You can pat the transplanted area but Do not rub. The donor area can be gently massaged, to get rid all the scabs. After 10 mins, rinse the shampoo off with indirect running water using a tumbler or a mug. Stop soaking your head as soon as the shampoo is rinsed off. It is not necessary to remove all the crusting during the first shower.
                                        <br/>
                                        Continue to follow the hair washing regime tabulated below.
                                    </p>

                                    <h5 className="mt-3" ref={ washingRef }><u>Hair Washing Regimen</u></h5>
                                    <b>IMPORTANT: <span className="text-danger">No chemical-based products such as hair colour, hair lacquer or sprays etc should be used on the head for the month following the procedure.</span></b>
                                    <div className="table-responsive">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Day</th>
                                                    <th>Instructions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <b>
                                                            <span style={{'whiteSpace':'nowrap'}}>3rd Day-4th Day</span>
                                                            <br/>
                                                            After Surgery
                                                        </b>
                                                    </td>
                                                    <td>
                                                        First wash to be performed on the third day following hair transplant. Wash the scalp with the Baby shampoo tapping gently over the transplanted area. Use your fingertips over the donor areas and rinse off. Rinse off very gently avoid a strong shower stream. You can leave it to dry naturally or use a clean cloth or tissue to dab it dry.
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <b>
                                                            <span style={{'whiteSpace':'nowrap'}}>5th Day-14th Day</span>
                                                            <br/>
                                                            (Wash Everyday)
                                                        </b>
                                                    </td>
                                                    <td>From 5th day, conditioner can be used. Wash the scalp with the shampoo as described above. If the scalp becomes very dry and itchy use the conditioner. Mix the conditioner with water in a small container and pour it all over the head. Soak for 10 Minutes and then rinse it gently.</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <b>
                                                            <span style={{'whiteSpace':'nowrap'}}>14th Day</span>
                                                            <br/>
                                                            (15th Day Onwards)
                                                        </b>
                                                    </td>
                                                    <td>
                                                        <b>On 14th day apply the shampoo on the transplanted area using circular massage movements and keep it on the scalp for 15 mins. Massage again and Rinse off the shampoo. You can repeat it again. Dry skin crusts should fall off.</b>
                                                        <br/>
                                                        Washing with the baby shampoo depends on the patient’s wishes. Patient at this point can revert to their normal shampoo.  For follow up purposes we provide an aftercare telephone consultation review within 7 days following then you will have post op review at 6th and 12th month’s time.
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    
                                    <p>If the grafts are soaked too long, they may swell and rise above the skin surface and appear as little white bumps immediately after washing. This is a problem more likely encountered the first few days after the procedure. It is not harmful to grafts but indicates that you are soaking too much. As soon as you allow your scalp to dry, they will disappear.</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div id="medicine">
                    <header>
                        <h3 className="text-center">MEDICATION REGIMEN</h3>
                    </header>
                    <div className="row mt-5">
                        <div className="col-sm-12 col-md-12 mb-5">
                            <div className="card" ref={ medicationRef }>
                                <div className="card-body">
                                    <p>
                                        After the procedure, medication plays an important role in aiding your recovery, managing the pain, and preventing of infection.
                                        <br/>
                                        Here at our clinic, the range of medication we provide are:
                                    </p>
                                    <ul>
                                        <li>Doxycycline - an antibiotic. Helps prevent any infection post procedure. Dosage is 100mg taken twice on the day of the surgery and one every day after lunch for next 6 days.</li>
                                    </ul>
                                    <p>
                                        We advise you follow the medication regime below. Please take care in ensuring you take the appropriate medication at the correct timings. If you have any questions, please do not hesitate to contact us.
                                    </p>
                                    <div className="table-responsive">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Day</th>
                                                    <th>Aftenoon<br/>After Food</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Day Of Procedure<br/>(Today)</td>
                                                    <td>
                                                        2 pills of Doxycycline
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>1</td>
                                                    <td>
                                                        1 pill of Doxycycline<br/>
                                                        <u>After Lunch</u>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>
                                                        1 pill of Doxycycline<br/>
                                                        <u>After Lunch</u>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>
                                                        1 pill of Doxycycline<br/>
                                                        <u>After Lunch</u>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>
                                                        1 pill of Doxycycline<br/>
                                                        <u>After Lunch</u>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>5</td>
                                                    <td>
                                                        1 pill of Doxycycline<br/>
                                                        <u>After Lunch</u>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>6</td>
                                                    <td>
                                                        1 pill of Doxycycline<br/>
                                                        <u>After Lunch</u>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Package Leaflet</td>
                                                    <td><a href="https://patient-portal.s3.fr-par.scw.cloud/website/medicationInstructions/Doxycycline.pdf" target="blank">Doxycycline Leaflet</a></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <p>Additionally, you can use the following medication on an <b><u>as required</u></b> basis:</p>
                                    <ul>
                                        <li>
                                            An antihistamine can be used if you experience itching to the scalp and it becomes intolerable. In such case you can take Piriton 4mg three times a day (which can cause drowsiness) or Cetirizine 10mg once daily (non-drowsy). Both are readily available over the counter medications. Also applying coconut oil or aloe vera gel will help reducing the itchiness. 
                                        </li>
                                        <li>
                                            Paracetamol - Painkiller can be used at the patient’s discretion. It can be used up to 2 tablets four times daily.
                                        </li>
                                        <li>
                                            Ibuprofen – Can be taken from 4th day of the procedure. It should be taken on a full stomach and so after meals.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="complication" ref={complicationRef}>
                    <header>
                        <h3 className="text-center">COMPLICATIONS THAT MAY OCCUR AFTER SURGERY</h3>
                    </header>
                    <div className="row mt-5">
                        <div className="col-sm-12 col-md-12 mb-5">
                            <div className="card">
                                <div className="card-body">
                                    <ol>
                                        <li>Swelling could occur usually 24 to 48 hours after the surgery. You may notice some swelling in the forehead, which may travel down towards the eyebrows. This will resolve itself, without intervention, usually 48 to 72 hours after it begins.</li>
                                        <li>Bleeding: there may be some bleeding (usually old blood stained with saline) directly after the surgery from the donor area, if any bleeding occurs, it is important to apply pressure with some gauze for as long as it takes to settle.</li>
                                        <li>Pain: sometimes pain can occur in the donor and the transplanted area, Paracetomol and ibuprofen are recommended. Please see specific instructions about pain relief provided.</li>
                                        <li>Numbness/ tingling sensation in the recipient area sometimes occurs, this will resolve without intervention. It can last from weeks to months.</li>
                                        <li>Infection: There is a very slight risk of infection as with any surgery. You should return to the clinic if you feel there is any signs of infection such as localised redness, pain.</li>
                                    </ol>
                                    <p className="mt-3">
                                        From three to four weeks post-op, you can expect that some transplanted hair will begin to shed. This is a normal process and should not be a concern. The healthy hair follicles are firmly in place at 10 days post-op and cannot be dislodged.
                                    </p>
                                    <p className="fw-bold">
                                        Please note that when hairs are shed, there may be a crust at the top and a bulb at the bottom. Remember the bulb is not the root (the growth part of the hair follicle). It is normal for the hair, root and associated crust to be shed and this does not represent a lost graft. If a graft is lost (something that may occur the first few days following the procedure) there will be bleeding at the site of the lost graft. Therefore, if you don’t see any bleeding, don’t be concerned.
                                    </p>
                                    <p className="text-danger">
                                        Do not use tar shampoo <span className="text-dark">(a dark-colored, medicated shampoo used for psoriasis)</span> on the transplanted area for 10 days following your procedure, as this may interfere with the growth of the grafts.
                                    </p>
                                    <table className="table table-bordered mt-5">
                                        <thead>
                                            <tr>
                                                <th colSpan="2">Timeline For Hair Growth</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>0-2 Weeks</td>
                                                <td>Follow the Post Op instructions.</td>
                                            </tr>
                                            <tr>
                                                <td>3-4 Weeks</td>
                                                <td>Shedding and shock loss starts.</td>
                                            </tr>
                                            <tr>
                                                <td>3-4 Months</td>
                                                <td>Hair starts growing.</td>
                                            </tr>
                                            <tr>
                                                <td>7-8 Months</td>
                                                <td>About 50 percent hair grows.</td>
                                            </tr>
                                            <tr>
                                                <td>12-16 Months</td>
                                                <td>The remaining 50 percent.</td>
                                            </tr>
                                            <tr>
                                                <td>16-18 Months</td>
                                                <td>Crown usually takes 18 months.</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default VishalPostop
