import React from 'react';
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import '../../css/Doctors.css';

function DrAlosert() {
    return (
        <div className="container doctor-profile-page mt-5">
            <h2 className="doctor-profile-page-name">Dr Mahdi Alosert</h2>
            <img src="https://patient-portal.s3.fr-par.scw.cloud/website/images/Doctors/DrAc.png" alt="Dr Alosert" className="float-sm-end" style={{height:'300px'}} />
            <ul className="doctor-list-style-none p-0">
                <li><IoMdCheckmarkCircleOutline size="20px" color="#191667" />  <b>Dr Alosert</b> is an experienced Principal Hair Restoration Surgeon in the UK; with 27-year-Multicultural Medical Experience, having qualified in 1994; He is a Consultant in Emergency Medicine, an experienced Musculoskeletal Sonographer and MSK ultrasound Injection Therapist.</li>
                <li><IoMdCheckmarkCircleOutline size="20px" color="#191667" />  <b>In France</b>, where Dr Alosert trained, has been awarded 5 post graduate qualifications and selected a Consultant, about 20 years ago, in Emergency Medicine for one of the Biggest & Busiest University Medical Hospital in Europe “HEH University Hospital/CHU-Lyon”</li>
                <li><IoMdCheckmarkCircleOutline size="20px" color="#191667" />  <b>His experience in Hair Restoration Field:</b>
                    <ul>
                        <li>Dr Alosert Underwent comprehensive hand-on trainings in Hair Loss, Hair Restoration and Hair Transplant Surgical Procedures in one of the largest and most successful Centres in the UK.</li>
                        <li>Dr Alosert’s rigorous training in Hair Restoration Sector has been enhanced by his extensive experience in Emergency Medicine dealing with complex soft tissue/ skin injuries & his experience in Musculoskeletal Injection therapy.</li>
                        <li>Dr Alosert has been awarded a membership of the European Organization of Hair Restoration Professionals [FUE-Europe.Org]</li>
                        <li>By now he performed over 1200 Hair Transplant Surgeries including British celebrity clienteles.</li>
                        <li>Dr Alosert uses his years of experience to provide patients a complete Hair Restoration Treatment. </li>
                        <li><b>Dr Alosert’s passion</b>, in performing highly professional Hair Restoration Surgical & non-surgical treatment, is well demonstrated by his willingness to use the most up-to-date techniques to achieve reliable, realistic & natural looking results.</li>
                    </ul>
                </li>
                <li><IoMdCheckmarkCircleOutline size="20px" color="#191667" />  <b>In Dubai Trauma Centre</b> which is one of the largest and advanced medical centres in the Middle East region with a JCI accreditation where Dr Alosert has used such experience to expand his multicultural professional experience in Emergency Medicine, managing extraordinary complex soft tissue injuries, established his Ultrasound guided regional nerve blocks core experience.</li>
                <li><IoMdCheckmarkCircleOutline size="20px" color="#191667" />  <b>In Dubai/UAE</b>, Dr Alosert has been nominated in multiple occasions to cover international Sport & Social events including Dubai film festival, Final Golf European Tour, Dubai World Cup Horse race, Dubai Auto-race, Beach Volleyball competitions, Dubai FINA Wold Swimming Championships ….etc]</li>
                <li><IoMdCheckmarkCircleOutline size="20px" color="#191667" />  <b>In the UK</b> where Dr Alosert has obtained 2 further post graduate qualifications to boost his knowledge and clinical skills in Emergency Medicine, in Diagnostic Musculoskeletal Ultrasound & in MSK injection therapy.</li>
                
                <div className="mt-1">
                    <h3 className="doctor-profile-page-name mt-3">Dr Alosert specialises in:</h3>
                    <ul className="doctor-list-style-none p-0">
                        <li><IoMdCheckmarkCircleOutline size="20px" color="#191667" />  Scalp & Hair assessment aiming for a tailored treatment in corporation with highly experienced Trichology consultants.</li>
                        <li><IoMdCheckmarkCircleOutline size="20px" color="#191667" />  Provide Pre and Post FUE assessment: rigorous follow-up.</li>
                        <li><IoMdCheckmarkCircleOutline size="20px" color="#191667" />  FUE techniques including shaven & unshaven ones.</li>
                        <li><IoMdCheckmarkCircleOutline size="20px" color="#191667" />  FUE techniques including shaven & unshaven ones.</li>
                        <li><IoMdCheckmarkCircleOutline size="20px" color="#191667" />  High density hair transplantation provided in one session, whenever suitable.</li>
                        <li><IoMdCheckmarkCircleOutline size="20px" color="#191667" />  Afro-Caribbean hair restoration.</li>
                        <li><IoMdCheckmarkCircleOutline size="20px" color="#191667" />  Eyebrows restoration [surgical and non-surgical treatment]</li>
                        <li><IoMdCheckmarkCircleOutline size="20px" color="#191667" />  Facial non-surgical rejuvenation: injection therapy [PRP, i-PRF, Mesotherapy]</li>
                        <li><IoMdCheckmarkCircleOutline size="20px" color="#191667" />  Scars revisions aiming for a safe & highly effective non-surgical treatment [injection therapy]</li>
                        <li><IoMdCheckmarkCircleOutline size="20px" color="#191667" />  Musculoskeletal ultrasound guided injection therapy</li>
                    </ul>
                </div>
                <div className="mt-1">
                    <h3 className="doctor-profile-page-name mt-3">Other interests:</h3>
                    <ul className="doctor-list-style-none p-0">
                        <li><IoMdCheckmarkCircleOutline size="20px" color="#191667" />  Managing Soft tissues injuries.</li>
                        <li><IoMdCheckmarkCircleOutline size="20px" color="#191667" />  Aesthetic treatment of different skin laceration & scars.</li>
                        <li><IoMdCheckmarkCircleOutline size="20px" color="#191667" />  Ultrasound guided Regional nerve block.</li>
                        <li><IoMdCheckmarkCircleOutline size="20px" color="#191667" />  Non-Surgical Liposuction (Vaser liposuction)</li>
                        <li><IoMdCheckmarkCircleOutline size="20px" color="#191667" />  Thread non-surgical lifting (Face-Neck)</li>
                        <li><IoMdCheckmarkCircleOutline size="20px" color="#191667" />  HIFU facial lifting (High-Intensity Focused Ultrasound Treatment)</li>
                        <li><IoMdCheckmarkCircleOutline size="20px" color="#191667" />  MSK Ultrasound private service.</li>
                    </ul>
                </div>
            </ul>
        </div>
    );
}

export default DrAlosert;
