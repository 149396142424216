import React, { useState, useEffect, useRef } from 'react';
import Navbar from '../components/Navbar/Navbar';
import StageNavbar from '../components/Stages/StageNavbar';
import { clinic0, clinic1, clinic2, clinic3, clinic4, clinic5 } from '../components/Navbar/NavbarData';
import axiosInstance from '../axios';
import ContactUs from '../components/ContactUs';
import { Kenton, London, Manchester, Manchester2, Romford } from './BranchData.js';
import { Link } from 'react-router-dom';
import { FaNutritionix, FaSmoking, FaDumbbell } from "react-icons/fa";

function Health() {
    const [name, setName] = useState({name: '', logo: '', email: ''});
    const contactRef = useRef();
    const [branchData, setbranchData] = useState();
    const [doctorId, setDoctorId] = useState();
    const [userAccount, setUserAccount] = useState();
    const [complaintsPolicyLink, setComplaintsPolicyLink] = useState();

    const nutritionRef = useRef();
    const exerciseRef = useRef();
    const smokingRef = useRef();

    const handleNutrition = () => nutritionRef.current.scrollIntoView({ behavior: 'smooth', block: "center" });
    const handleExercise = () => exerciseRef.current.scrollIntoView({ behavior: 'smooth', block: "center" });
    const handleSmoking = () => smokingRef.current.scrollIntoView({ behavior: 'smooth', block: "center" });

    useEffect(() => {
        axiosInstance.get('clinic-detail/').then((res) => {
            console.log(res);
            console.log(res.data);
            let data;
            let clinic = res.data.clinic_name;
            let doctorSelection = res.data.doctor_name;
            setUserAccount(res.data.username);
            if(clinic === 1 && doctorSelection === 2){
                data = clinic0;
                setbranchData(Kenton);
                setDoctorId("Dr Alosert");
                setComplaintsPolicyLink('https://s3.eu-west-1.wasabisys.com/medical-checklist/media/files/2022/07/22/d3b59355-9732-46b3-bd4f-31179cf4c1f9.pdf?AWSAccessKeyId=514TT1YU9CA9QCBEI5MS&Signature=q6hc0Iowh8gqCJWtY%2FdoVo6EhXA%3D&Expires=1659401773');
            } else if(clinic === 3) {
                data = clinic1;
                setbranchData(Manchester);
                setDoctorId("Dr Ditta");
                setComplaintsPolicyLink('https://s3.eu-west-1.wasabisys.com/medical-checklist/media/files/2022/07/07/75cb4ea8-adc5-4c67-a02a-2010ecd20a7f.pdf?AWSAccessKeyId=514TT1YU9CA9QCBEI5MS&Signature=TqFv%2Bg59VofqfqtLb28IV881hA4%3D&Expires=1659401256');
            } else if(clinic === 4) {
                data = clinic2;
                setbranchData(Manchester2);
                setDoctorId("Dr Munir");
                setComplaintsPolicyLink('https://s3.eu-west-1.wasabisys.com/medical-checklist/media/files/2022/07/07/75cb4ea8-adc5-4c67-a02a-2010ecd20a7f.pdf?AWSAccessKeyId=514TT1YU9CA9QCBEI5MS&Signature=TqFv%2Bg59VofqfqtLb28IV881hA4%3D&Expires=1659401256');
            } else if(clinic === 1 && doctorSelection === 3) {
                data = clinic3;
                setbranchData(Kenton);
                setDoctorId("Dr Sami");
                setComplaintsPolicyLink('https://s3.eu-west-1.wasabisys.com/medical-checklist/media/files/2022/07/22/d3b59355-9732-46b3-bd4f-31179cf4c1f9.pdf?AWSAccessKeyId=514TT1YU9CA9QCBEI5MS&Signature=q6hc0Iowh8gqCJWtY%2FdoVo6EhXA%3D&Expires=1659401773');
            } else if(clinic === 0 && doctorSelection === 0) {
                data = clinic4;
                setbranchData(London);
                setDoctorId("Dr Shuaib");
                setComplaintsPolicyLink('https://s3.eu-west-1.wasabisys.com/medical-checklist/media/files/2022/07/22/d3b59355-9732-46b3-bd4f-31179cf4c1f9.pdf?AWSAccessKeyId=514TT1YU9CA9QCBEI5MS&Signature=q6hc0Iowh8gqCJWtY%2FdoVo6EhXA%3D&Expires=1659401773');
            } else if(clinic === 2) {
                data = clinic5;
                setbranchData(Romford);
                setDoctorId("Dr Vishal");
                setComplaintsPolicyLink('https://s3.eu-west-1.wasabisys.com/medical-checklist/media/files/2022/07/07/75cb4ea8-adc5-4c67-a02a-2010ecd20a7f.pdf?AWSAccessKeyId=514TT1YU9CA9QCBEI5MS&Signature=SQ1zBAphzSRYKuYDj6ZKcym5XYY%3D&Expires=1659401861');
            } else if(clinic === 0 && doctorSelection === 1) {
                data = clinic4;
                setbranchData(London);
                setDoctorId("Dr Rafiq");
                setComplaintsPolicyLink('https://s3.eu-west-1.wasabisys.com/medical-checklist/media/files/2022/08/02/d663a697-06f0-4568-a624-daaf1fae5096.pdf?AWSAccessKeyId=514TT1YU9CA9QCBEI5MS&Signature=Zs%2Bq1bdVzEZqMHKx1RLCJNUU5gc%3D&Expires=1659403770');
            }

            setName({ name: data.name, logo: data.logo, email: data.email, reference: contactRef });
        });
    }, []);

    return (
        <div>
            <Navbar {...name} /><hr className="m-0 p-0" />
            <StageNavbar reference={contactRef} doctorId={doctorId} /><hr className="m-0 p-0" />
            <div className="my-5">
                <header ref={ nutritionRef }>
                    <h3 className="text-center">Health Promotion</h3>
                </header>

                <div className="row px-3 mt-5">
                    <div className="col-md-4">
                        <div className="border d-none d-md-block" style={{'position':'sticky', 'top':100}}>
                            <ul className="nav nav-pills d-flex flex-column" style={{'listStyle':'none'}}>
                                <li className="nav-item">
                                    <a className="nav-link active" data-bs-toggle="pill" href="#nutrition">Nutrition</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" data-bs-toggle="pill" href="#exercise">Exercise</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" data-bs-toggle="pill" href="#smoking">Smoking</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className='tab-content'>
                            <div id="nutrition" className='tab-pane container active'>
                                <header>
                                    <h3 className="text-center">NUTRITION</h3> 
                                    <h3 className='text-center'><FaNutritionix size={50}/></h3>
                                </header>
                                <div className="row mt-5">
                                    <div className="col-sm-12 col-md-12 mb-5">
                                        <div className="card">
                                            <div className='card-body'>
                                                <div>
                                                    <p>Maintaining good nutrition has numerous health benefits, some that are particularly noticeable through your outward appearance.</p>
                                                    <p>The overall appearance of your hair can give great indication to your general health within. Hair follicles require key nutrients and vitamins for growth and to maintain strength.</p>
                                                </div>

                                                <div className='mt-5'>
                                                    <strong>Protein</strong>
                                                    <p>Hair is made of protein, therefore it is important to have a sufficient amount protein in your diet for strong and healthy looking hair. Avoid low protein diets as this may result in weaker and thinning hair overtime.</p>
                                                    <p>Good sources of protein are chicken, turkey, fish, dairy and eggs. For vegetarians, foods such as legumes, lentils, cottage cheese and nuts are great for maintaining healthy and strong hair.</p>
                                                </div>

                                                <div className='mt-5'>
                                                    <strong>Iron & Vitamin C</strong>
                                                    <p>Iron is a key nutrient for maintaining healthy hair. The follicles are enriched by the nutrients supplied through the blood to the root of the hair.</p>
                                                    <p>Lack of iron can lead to anaemia, which affects the nutrient supply to the hair. This could eventually lead to hair loss and the lack of nutrient supply will cause to the hair to weaken and thin.</p>
                                                    <p>Natural sources of iron can be found in red meat, chicken and fish. For vegetarians a good source of iron can be lentils, spinach, broccoli, kale and salad greens.</p>
                                                    <p>Vitamin C is an anti-oxidant and aids the absorption of iron. Natural sources of Vitamin C can be found in blackcurrants, blueberries, guava, kiwi, oranges, papaya, strawberries and sweet potatoes.</p>
                                                </div>

                                                <div className='mt-5'>
                                                    <strong>Omega-3</strong>
                                                    <p>Omega-3 fatty acids are found in the cells that line the scalp and provide the oils that keep your scalp and hair hydrated.</p>
                                                    <p>Good sources of Omega-3 can be found in oily fish such as salmon, herring, sardines, trout and mackerel. Vegetarian sources such as avocado, pumpkin seeds and walnuts are good for Omega-3.</p>
                                                </div>

                                                <div className='mt-5'>
                                                    <strong>Zinc</strong>
                                                    <p>Zinc is another key nutrient for a healthier hair and scalp. Insufficient levels of zinc may lead to weaker/thinning hair as well as a dry and flaky scalp.</p>
                                                    <p>Good sources of zinc are cereals, oysters, eggs and whole grains.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div id="exercise" className='tab-pane container fade'>
                                <header>
                                    <h3 className="text-center">EXERCISE</h3> 
                                    <h3 className='text-center'><FaDumbbell size={50}/></h3>
                                </header>
                                <div className="row mt-5">
                                    <div className="col-sm-12 col-md-12 mb-5">
                                        <div className="card" ref={exerciseRef}>
                                            <div className="card-body">
                                                <p>
                                                    Exercise is an important factor in maintaining a healthy lifestyle but also helps nourish healthier hair follicles. Exercise can help your hair by:
                                                </p>
                                                <ol className="ditta-preop-instruction-list">
                                                    <li><b>Reducing the stress levels</b><br/>Stress is one of the reasons that may cause an individual to suffer from hair loss. The human body produces stress hormones called cortisone, which affect the entire body including the scalp and hair. Exercise produces serotonin ‘happy hormones’ that help reduce the level of stress.</li>
                                                    <li><b>Increasing blood flow</b><br/>Exercise increases blood flow around the body including the scalp. Blood flow provides key nutrients and oxygen to help nourish the hair follicles to promote healthy hair growth.</li>
                                                    <li><b>Flushing out toxins through sweat</b><br/>Toxins can build up around the root of the hair causing the hair to weaken over time. Exercise can help clear out toxins through sweat, thereby unclogging the hair follicles to help promote healthy hair growth.</li>
                                                </ol>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div id="smoking" className='tab-pane container fade'>
                                <header>
                                    <h3 className="text-center">SMOKING</h3> 
                                    <h3 className='text-center'><FaSmoking size={50}/></h3>
                                </header>
                                <div className="row mt-5">
                                    <div className="col-sm-12 col-md-12 mb-5">
                                        <div className="card" ref={smokingRef}>
                                            <div className="card-body">
                                                <p>Smoking is not only a preventable cause of significant systemic disease but also affects the follicular growth cycle and fiber pigmentation. Ambient tobacco smoke exposure results in nicotine accumulation in hair follicles and the hair shaft.</p>
                                                <p>The most common type of alopecia, androgenetic alopecia (AGA), is multifactorial. Systemic androgens have been shown to play a role in the pathogenesis of AGA by causing hair miniaturization and transforming terminal to vellus hairs. The role of environmental factors, such as smoking, has been implicated to negatively affect hair growth.</p>
                                                <p>Smoking is a risk factor for many preventable morbidities, including cerebrovascular, pulmonary disease, malignancy, and oral mucocutaneous disease. More recently, the role of smoking in skin aging and hair loss has been a great topic of interest and research. Animal studies in mice have indicated that environmental factors, including tobacco smoke exposure, can lead to alopecia and premature hair graying (PHG). Chemical components found in tobacco smoke, such as nicotine, are capable of accumulating in hair, and have become reliable biomarkers for detecting smoke exposure.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            <ContactUs reference={contactRef} branchData={branchData} doctorId={doctorId} />
        </div>
    )
}

export default Health
