import { RiHome2Fill, RiSurgicalMaskFill, RiStethoscopeLine, RiClipboardFill, RiHeart2Fill } from "react-icons/ri";

export const linkZero = {
    link: '/',
    icon: <RiHome2Fill />,
    stageName: 'Home'
}

export const linkOne = {
    link: '/preop',
    icon: <RiSurgicalMaskFill />,
    stageName: 'Pre-op'
};

export const linkTwo = {
    link: '/postop',
    icon: <RiStethoscopeLine />,
    stageName: 'Post-op'
};

export const linkThree = {
    link: '/faq',
    icon: <RiClipboardFill />,
    stageName: 'FAQ'
};

export const linkFour = {
    link: '/health',
    icon: <RiHeart2Fill />,
    stageName: 'Health Promotion'
};