import React from 'react';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';

function AlosertPreop() {
    return (
        <div className="px-3 mt-5">
            <header>
                <h3 className="text-center">WHAT TO DO AND WHAT NOT TO DO BEFORE THE FUE SURGERY</h3>
            </header>
            <div className="row mt-5">
                <div className="col-sm-12 col-md-6 mb-5">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">The Do's</h5>
                        </div>
                        <ReactPlayer url='https://patient-portal.s3.fr-par.scw.cloud/website/videos/Alosert/preop_do.mp4' className="card-img-bottom" width='100%' height='300px' controls={true}/>
                        <div className="card-body">
                            <ul>
                                <li>FUE, the hair transplant, is a surgical procedure like any other surgery that you must be ready for it.</li>
                                <li>Do wash your hair & scalp carefully the night before the surgery and repeat the hair & scalp wash the morning of the surgery [preferably with baby shampoo]</li>
                                <li>Do choose comfortable clothes so that you can take them off easily and safely for the hair grafts immediately after the hair surgery, so No T-shirt or any clothes cause any pressure on your head.</li>
                                <li>Do take your daily medications if you are on regular prescription for diabetes, high blood pressure, thyroid conditions, heart disease or epilepsy and according to the prescription of your treating GP / Specialist BUT Do STOP any blood thinners for a week before and a week after the surgery [You must get a written permission letter from your treating doctor, your GP or specialist, whoever put you on any blood thinner]</li>
                                <li>Do talk with your treating doctor, GP/ Specialist, about your hair surgery, if you have any chronic medical conditions as diabetes, hypertension, thyroid illnesses, blood disorders, heart diseases or Epilepsy to plan and review your regular medications before the hair surgery.</li>
                                <li>Do have a light breakfast BUT  no Energy drinks or Caffeine contain drinks as tea or coffee [risk of palpitations or heart racing]</li>
                                <li>Do Leave the hair Clinic’s number with your next of kin, similarly, do provide the hair clinic your emergency number [for any unexpected emergency conditions]</li>
                                <li>Do come to the hair clinic by using the right way of transport and without tiring yourself, So Do ask someone to drop you to the clinic or use the public transport.</li>
                                <li>Do arrange off work for few days, 3-7 days, depending on the nature of your work [It can be longer if unsafe for your hair surgery]</li>
                                <li>You will be asked to sign a consent form on your surgery day. Please have a read through the consent form as it will speed up the process during your surgery date. <Link to="/patient-consent-form" className=''>Click me to view the consent form.</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6 mb-5">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">The Don'ts</h5>
                        </div>
                        <ReactPlayer url='https://patient-portal.s3.fr-par.scw.cloud/website/videos/Alosert/preop_dont.mp4' className="card-img-bottom" width='100%' height='300px' controls={true}/>
                        <div className="card-body">
                            <ul>
                                <li>Do NOT drive the car to our clinic; it is unsafe to drive home after the surgery.</li>
                                <li>Do NOT bring any valuables other than your surgical procedure’s payment balance.</li>
                                <li>Do NOT take any blood thinner medications or any medications contain salicylic acid for 7 days before the surgery [Do NOT stop them without a written permission letter from whoever put you on, whether your GP or treating specialist, permits you to stop them for at least 7 days before & 7 days after the hair surgery [High risk of uncontrolled bleeding]</li>
                                <li>Do NOT take any Anti-inflammatory pills [NSAID] like Nurofen/ Ibuprofen/ Naproxen/ Diclofenac/ Celebrex…etc. For 7 days before the surgery [Affects the healing process]</li>
                                <li>Do NOT have any Energy drinks or any Caffeine contain Drinks like tea or coffee at least 1 day before the surgery.</li>
                                <li>Do NOT shave or cut your hair completely or too short. You will have that done in our clinic & it is important for us to know your hair natural directions.</li>
                                <li>Do NOT use any Unnecessary medications before your surgery without consulting your surgeon [Take only your regular medications Except the blood thinners and ONLY after having your treating doctor GP/ specialist written permission letter]</li>
                                <li>Do NOT smoke any kind including cigarette, vaping and shisha, for 3 days before your surgery [Affect the blood flow rate and affect the grafts re-growth rate]</li>
                                <li>Do NOT take multivitamins, food supplements, alcoholic drinks or creative drugs for 3-5 days before your surgery [interact with the surgery’s medications & affect the healing]</li>
                                <li>Do NOT apply any hair style spray, cream, hair conditioners, hair fibres, wigs adhesives or any ointments.</li>
                                <li>Do NOT wear any tight clothes or any ones put pressure over the transplanted area [high risk of grafts dislodgement with T-shirt, jumpers, sweaters or any other pullovers][So Do wear button-down shirts, sweaters with zipper]</li>
                                <li>
                                    The following list of supplements, vitamins and herbs has to be stopped 7 days before the surgery to stop unexpected excessive bleeding during your hair surgery (excess bleeding might affect the number of grafts you can have and affect the final density):
                                    <ul>
                                        <li>Berries</li>
                                        <li>Ajoene</li>
                                        <li>Birch bark</li>
                                        <li>Cayenne</li>
                                        <li>Chinese black tree fungus</li>
                                        <li>Cumin</li>
                                        <li>Evening primrose oil</li>
                                        <li>Feverfew</li>
                                        <li>Garlic</li>
                                        <li>Ginger</li>
                                        <li>Ginkgo biloba</li>
                                        <li>Ginseng extract</li>
                                        <li>Milk thistle</li>
                                        <li>Omega 3 fatty acids</li>
                                        <li>Onion extract</li>
                                        <li>St. John's wort</li>
                                        <li>Tumeric</li>
                                        <li>Vitamins C &amp; E</li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AlosertPreop
