import React from 'react';

function Form({address, contact, googleSrc, parking, bus, train, doctorId}) {

    return (
        <div className="row">
            <div className="col-lg-6 rounded py-3" style={{ backgroundColor:'#D9D9D9' }}>
                <form id="contact-form" className="pageclip-form h-100">
                    <div>
                        <input className="d-none" type="text" name="doctor_name" defaultValue={doctorId} />
                    </div>
                    <div className="form-group col-md-12 my-3">
                        <label><b>Your details</b></label>
                        <input className="form-control" type="text" placeholder="First name" name="first_name" maxLength='80' data-parsley-required  />
                    </div>
                    <div className="form-group col-md-12 my-3">
                        <input className="form-control" type="text" placeholder="Last name" name="last_name" maxLength="100" data-parsley-required />
                    </div>
                    <div className="form-group col-md-12 my-3">
                        <input className="form-control" type="tel" placeholder="Phone Number" name="phone_number" data-parsley-required />
                    </div>
                    <div className="form-group col-md-12 my-3">
                        <input className="form-control" type="email" placeholder="Email" name="email" data-parsley-required />
                    </div>
                    <div className="form-group col-md-12 my-3">
                        <label><b>Date of surgery</b></label>
                        <input className="form-control" type="date" placeholder="Date Of Surgery" name="date_of_surgery" data-parsley-required />
                    </div>
                    <div className="form-group col-md-12 my-3">
                        <textarea className="form-control" type="text" placeholder="Your Query" name="query" data-parsley-required></textarea>
                    </div>
                    <div className="d-none">
                        <input className="image-url" />
                    </div>
                    <div className="DashboardContainer"></div>
                    <span id="addpics" className="btn" style={{backgroundColor: 'white', color:'black', border:'2px solid #4CAF50'}}>Upload Pictures</span>
                    <button id="submitBtn" type="submit" className="btn btn-primary btn-lg btn-block mt-5 w-100 pageclip-form__submit text-center" style={{whiteSpace:'normal', wordWrap:'break-word'}}>
                        Send Form
                    </button>
                    <br/><br/>
                    {doctorId == 'Dr Vishal' ? '' : <b className="mt-3" style={{color:'darkred'}}>To arrange your 6 or 12 months post operative review, please contact our medical team using the form above.</b>}
                    <br/>
                    <small>In the case of any emergency, the patient is advised to have a low thresold for self referal to a&e. This is the case for both in hours and out of hours.</small>
                </form>
            </div>
            <div className="col-lg-6 py-5 align-self-center text-white-50">
                <div className="text-center">
                    {address}
                </div>
                    
                <iframe src={googleSrc} width="100%" height="380" frameBorder="0" scrolling="0" marginHeight="0" marginWidth="0" loading="lazy"></iframe>
                <p>
                    <br/>
                    {parking === '' ?  '':<span><br/><b>Parking:</b> <span dangerouslySetInnerHTML={{ __html: parking }}></span></span>}
                    {bus === '' ?  '':<span><br/><b>By Bus:</b> <span dangerouslySetInnerHTML={{ __html: bus }}></span></span>}
                    {train === ''? '':<span><br/><b>By Train:</b> {train}</span>}
                </p>
            </div>
            
        </div>
    )
}

export default Form;
