import React, { useState, useEffect } from 'react';
import './HomepageImageGallery.css';

const HomepageImageGallery = () => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [shuffledImages, setShuffledImages] = useState([]);
    const interval = 12000;
    const images = [
        'https://s3.eu-central-1.wasabisys.com/patient-portal/twentyoneagainImages/image3',
        'https://s3.eu-central-1.wasabisys.com/patient-portal/twentyoneagainImages/image5',
        'https://s3.eu-central-1.wasabisys.com/patient-portal/twentyoneagainImages/image6',
        'https://s3.eu-central-1.wasabisys.com/patient-portal/twentyoneagainImages/image7',
        'https://s3.eu-central-1.wasabisys.com/patient-portal/twentyoneagainImages/image8',
    ];

    useEffect(() => {
        const shuffleArray = (array) => {
            for (let i = array.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [array[i], array[j]] = [array[j], array[i]];
            }
            return array;
        };

        const shuffled = shuffleArray([...images]);
        setShuffledImages(shuffled);

        setCurrentImageIndex(0); // Reset to first image after shuffling

        const intervalId = setInterval(() => {
            setCurrentImageIndex(prevIndex => (prevIndex + 1) % shuffled.length);
        }, interval);

        return () => clearInterval(intervalId);  // Cleanup interval on component unmount
    }, [interval]); // Run only once on mount

    return (
        <div className="image-gallery bannerimage-container">
            {shuffledImages.length > 0 && (
                <img src={shuffledImages[currentImageIndex]} alt={`slide-${currentImageIndex}`} style={{height: '410px', width:'100%', border:'1px solid black'}} />
            )}
        </div>
    );
};

export default HomepageImageGallery;
