import React from 'react';
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import '../../css/Doctors.css';

function DrMunir() {
    return (
        <div className="container doctor-profile-page mt-5">
            <h2 className="doctor-profile-page-name">Dr Masood Munir</h2>
            <img src="https://patient-portal.s3.fr-par.scw.cloud/website/images/Doctors/DrMc.png" alt="Dr Munir" className="float-sm-end" style={{height:'300px'}} />
            <ul className="doctor-list-style-none p-0">
                <li><IoMdCheckmarkCircleOutline size="20px" color="#191667" />  Worked for 8 years in the hair transplant industry.</li>
                <li><IoMdCheckmarkCircleOutline size="20px" color="#191667" />  Specialises in FUE/afro hair transplant procedures.</li>
                <li><IoMdCheckmarkCircleOutline size="20px" color="#191667" />  Quality driven, and an aesthetical connoisseur.</li>
                <li><IoMdCheckmarkCircleOutline size="20px" color="#191667" />  PRP and Botox specialist.</li>
                <li><IoMdCheckmarkCircleOutline size="20px" color="#191667" />  Extensive portfolio of successful patients.</li>
                <li><IoMdCheckmarkCircleOutline size="20px" color="#191667" />  Sought after surgeon, in high demand due to the speciality in afro hair transplant.</li>
                <li><IoMdCheckmarkCircleOutline size="20px" color="#191667" />  Bilingual.</li>
                <li><IoMdCheckmarkCircleOutline size="20px" color="#191667" />  Dr Munir is a very well-established Dr with a wealth of experience in many of areas of expertise.</li>
                <li><IoMdCheckmarkCircleOutline size="20px" color="#191667" />  As an added bonus Dr Munir, also specialised in minimum invasive cosmetic procedures.</li>
            </ul>
        </div>
    );
}

export default DrMunir
