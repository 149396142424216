import React, { useRef } from 'react';
import ReactPlayer from 'react-player';
import { TiTick, TiTimes} from "react-icons/ti";
import ImageGallery from '../../components/PromoImageGallery/ImageGallery';


function AlosertPostop() {
    const generalRef = useRef();
    const washingRef = useRef();
    const medicationRef = useRef();


    const handleGeneral = () => generalRef.current.scrollIntoView({ behavior: 'smooth', block: "center" });
    const handleWashing = () => washingRef.current.scrollIntoView({ behavior: 'smooth', block: "center" });
    const handleMedication = () => medicationRef.current.scrollIntoView({ behavior: 'smooth', block: "center" });

    return (
        <div className="row px-3 mt-5">
            <div className="col-md-3">
                <div className='d-sm-block d-md-none border mb-5 pb-5'>
                    <ImageGallery />
                </div>
                <div className="border d-none d-md-block" style={{'position':'sticky', 'top':100}}>
                    <ul className='border-bottom py-3' style={{'listStyle':'none'}}>
                        <li className="py-1">
                            <span className="sidebarNav" onClick={handleGeneral}>
                                The Do's And Don'ts
                            </span>
                        </li>
                        <li className="py-1">
                            <span className="sidebarNav" onClick={handleWashing}>
                                Hair Washing Regimen
                            </span>
                        </li>
                        <li className="py-1">
                            <span className="sidebarNav" onClick={handleMedication}>
                                Medication Regimen
                            </span>
                        </li>
                    </ul>

                    <div className='mt-3'>
                        {/* <div className='bannervid-container' style={{background: '#242424'}}>
                            <video playsinline="playsinline" autoplay="autoplay" muted="muted" loop="loop">
                                <source src="https://s3.eu-central-1.wasabisys.com/patient-portal/banner_video/twentyoneagainbanner.mov" type="video/mp4" />
                            </video>
                        </div> */}
                        <ImageGallery />
                    </div>
                </div>
            </div>
            <div className="col-md-8">
                <div id="post-instructions">
                    <header>
                        <h3 className="text-center">Post Operatives Instructions</h3>
                    </header>
                    <div className="row mt-5" ref={ generalRef }>
                        <div className="col-sm-12 col-md-12 mb-5">
                            <div className="card">
                                <ReactPlayer url='https://patient-portal.s3.fr-par.scw.cloud/website/videos/Alosert/new_post_instructions.mp4' className="card-img-bottom" width='100%' height='450px' controls={true}/>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-12 col-md-6">
                                            <h5>The Do's</h5>
                                            <ul style={{'list-style': 'none', 'paddingLeft': 0}}>
                                                <li className="pb-2"><TiTick color="green" />Do follow and act according to the instructions given by the surgery team.</li>
                                                <li className="pb-2"><TiTick color="green" />Do Use travel pillow while sleeping to support the neck and head for 5 days after surgery.</li>
                                                <li className="pb-2"><TiTick color="green" />Do Keep your scalp clean and hygienic [healthy scalp= healthy grafts]</li>
                                                <li className="pb-2"><TiTick color="green" />Do take the medications as been prescribed/ advised by the surgical team.</li>
                                                <li className="pb-2"><TiTick color="green" />Do apply the ice pack on the headband to decrease the swelling.</li>
                                                <li className="pb-2"><TiTick color="green" />Do spray the transplanted area with mineral water / saline fluid as advised [use the spray bottle frequently]</li>
                                                <li className="pb-2"><TiTick color="green" />Do sleep on your back, head elevated 45 degrees [put 2 extra pillows and put the travel pillow around your neck for the first 4-5 days to have less swelling and to protect the transplanted area]</li>
                                                <li className="pb-2"><TiTick color="green" />Do contact the clinic if any concerns during the opening hours or to contact the 111 for any emergency/ urgent concerns.</li>
                                                <li className="pb-2"><TiTick color="green" />Do take regular pain killer for 2 days then as needed; Take Paracetamol 500mg X 2 tablets every 6 hours. Do NOT take more than 8 tablets of 500mg per day; In addition, you can take codeine But Do Not take Ibuprofen or Nurofen or similar family medications [No NSAID Or Aspirin]</li>
                                                <li className="pb-2"><TiTick color="green" />Do keep and adjust the headband for 5 days [day & night], However, you remove it while washing your head and to adjust it if it is too tight or too loose. </li>
                                                <li className="pb-2"><TiTick color="green" />Do remove the dressing / bandage from the donor area 24 hours after the surgery & wash the area with baby shampoo [please watch our washing regimen video]</li>
                                                <li className="pb-2"><TiTick color="green" />Do wash the transplanted area from the day 3 after the surgery, with baby shampoo [gently without touching or rubbing the area], fill a cup with warm water and pour / rinse the head thoroughly but NO shower.</li>
                                                <li className="pb-2"><TiTick color="green" />Do wash the head every day till day 14, the washing duration to be gradually increased day-by-day [please watch our washing regimen video]</li>
                                                <li className="pb-2"><TiTick color="green" />Do eat healthy, ideally to have soft or semi-soft food [less chewing force while eating] for 3 days; Do eat a lot protein & drink a lot of water for 7 days [good nutrition and hydration] BUT less salt or NO salty foods for 5 days [to have less swelling]</li>
                                                <li className="pb-2"><TiTick color="green" />Do your daily light social activities [no excessive exercises]</li>
                                                <li className="pb-2"><TiTick color="green" />If you have itchiness [please check the medications regimen section on our website]</li>
                                                <li className="pb-2"><TiTick color="green" />Do take your regular medications for your chronic conditions as High blood pressure, Heart disease, Diabetes, Epilepsy, Thyroid conditions..etc. [please check the medications section on our website]</li>
                                            </ul>
                                        </div>
                                        <div className="col-sm-12 col-md-6">
                                            <h5>The Don'ts</h5>
                                            <ul style={{'list-style': 'none', 'paddingLeft': 0}}>
                                                <li className="pb-2"><TiTimes color="red" />Do NOT Drive immediately after the surgery (for your safety, find someone to drive you home or use public transport)</li>
                                                <li className="pb-2"><TiTimes color="red" />Do NOT apply anything on the head unless advised by the hair surgical team.</li>
                                                <li className="pb-2"><TiTimes color="red" />Do NOT bend over or lean forwards or turn your head upside down for 2 days. </li>
                                                <li className="pb-2"><TiTimes color="red" />Do NOT sleep on the transplant area; do NOT sleep on your tummy for 7 days.</li>
                                                <li className="pb-2"><TiTimes color="red" />Do NOT apply icepack directly on the surgery areas [damaging the surgery site]</li>
                                                <li className="pb-2"><TiTimes color="red" />Do NOT touch or itch the transplanted area [spray it with water and take antihistamine tablets if need][please check the medications section on our website]</li>
                                                <li className="pb-2"><TiTimes color="red" />Do NOT Have Sex for 7 days after surgery [causing excessive sweating and swelling]</li>
                                                <li className="pb-2"><TiTimes color="red" />Do NOT Eat Fast Food for 2 weeks [causing further dryness]</li>
                                                <li className="pb-2"><TiTimes color="red" />Do NOT Eat cake (sweets) for 2 weeks [causing sugar imbalance and affects the healing]</li>
                                                <li className="pb-2"><TiTimes color="red" />Do NOT Drink fizzy drinks for 2 weeks [causing dryness]</li>
                                                <li className="pb-2"><TiTimes color="red" />Do NOT drink ALCOHOL or having CAFFEINE drinks or ENERGY drinks for at least 5-7 days [it’s better to stop drinking alcohol to have healthy scalp and healthy new hairs]</li>
                                                <li className="pb-2"><TiTimes color="red" />Do NOT SMOKE including Vaping or Shisha for at least 2 months</li>
                                                <li className="pb-2"><TiTimes color="red" />Do NOT eat spicy foods for 7 days [spicy food cause dryness]</li>
                                                <li className="pb-2"><TiTimes color="red" />Do NOT use shower handle to wash your head for 14 days [no pressurised water], you can use a cup filled with warm water instead while washing your head.</li>
                                                <li className="pb-2"><TiTimes color="red" />Do NOT put ANY kind of hats or head cover for 7 days [if desperate then you can ONLY put a loose and adjustable Caps as shown in the below picture, and NOT other types of hats, from day 7 after the surgery & ONLY use the Caps for short time periods during the day], however, you will be free to use any kind of hats 3 weeks after the surgery]</li>
                                                <img src="https://patient-portal.s3.fr-par.scw.cloud/website/images/alosertHats.png" alt="Hats" className="img-fluid" />
                                                <li className="pb-2"><TiTimes color="red" />Do NOT put hair pieces or wigs for at least 14 days, however, for 6 months if you use glue or hair piece adhesives.</li>
                                                <li className="pb-2"><TiTimes color="red" />Do NOT shave your transplanted area for at least 2 months; however, gentle hair dressing can be done by using scissors, without TRACTION, on the donor area 14 days after the surgery.</li>
                                                <li className="pb-2"><TiTimes color="red" />Do NOT Dye Your Hair for 6 months after surgery (You can dye it BEFORE the surgery), however, after 6 months, you can ONLY use organic dye products up to 12 months.</li>
                                                <li className="pb-2"><TiTimes color="red" />Do NOT leave the scalp without daily wash [daily wash from day 3 to day 14] [You can even have 2 short head washes per day from day 10 till day 14][to keep a healthy scalp]</li>
                                                <li className="pb-2"><TiTimes color="red" />Do NOT take sun bath or sauna for 4 to 6 weeks.</li>
                                                <li className="pb-2"><TiTimes color="red" />Do NOT expose the scalp to sun light for at least 4 weeks [You can put ONLY loose Caps and NOT other kinds of hats from day 7 to day 21 to protect your scalp from direct sun light.</li>
                                                <li className="pb-2"><TiTimes color="red" />Do NOT perform strenuous exercise /heavy weight sports for 4 weeks, However, Simple gym exercise can be re-started 2 weeks after the surgery.</li>
                                                <li className="pb-2"><TiTimes color="red" />Do NOT swim in the sea for 4 wks.</li>
                                                <li className="pb-2"><TiTimes color="red" />Do NOT swim in swimming pool for 3 months [contains chemicals damaging the surgery site]</li>
                                                <li className="pb-2"><TiTimes color="red" />Do NOT perform direct contact sports [Rugby, boxing, paly fighting, taekwondo…etc] for 3-6 months [damaging new hair grafts]</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="washing">
                    <header>
                        <h3 className="text-center">Post Hair Surgery Washing Regimen</h3>
                    </header>
                    <div className="row mt-5">
                        <div className="col-sm-12 col-md-12 mb-5">
                            <div className="card">
                                <ReactPlayer url='https://patient-portal.s3.fr-par.scw.cloud/website/videos/Alosert/washing.mp4' className="card-img-bottom" width='100%' height='450px' controls={true}/>
                                <div className="card-body" ref={ washingRef }>
                                    <ul>
                                        <li>Please refer to the relevant videos on our website for further details.</li>
                                        <li>Use a cup and warm water, No shower or pressurised water for 7 days.</li>
                                        <li>Make your head wet with warm water before you apply the baby shampoo.</li>
                                        <li>No scratching or rubbing while washing your head.</li>
                                        <li>Wash your head every day from day 3 to day 14 [even 2 times per day]</li>
                                        <li>Leave your head to dry naturally.</li>
                                        <li>Do not use the towel on the transplanted</li>
                                        <li>Do not use the hair dryer.</li>
                                        <li>Remove the headband while washing the head [apply the headband for 5 days only]</li>
                                    </ul>
                                

                                    <h5 className="mt-3" ref={ washingRef }><u>Hair Washing Regimen</u></h5>
                                    <div className="table-responsive">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Day</th>
                                                    <th>Instructions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>0</td>
                                                    <td>
                                                        Surgery day [keep spray the transplanted area with mineral water every 20 minutes]
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>1</td>
                                                    <td>
                                                        Remove the headband 1st , then Pour warm water over the dressing of the donor area before you remove the dressing gently, wash the donor area with the Baby-Shampoo and no further dressing required [leave it to dry naturally, no hair dryer]
                                                        <br/>
                                                        Spray the transplanted area with mineral water every 30 minutes.
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>
                                                        Keep spray the transplanted area with mineral water every 30 minutes. 
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>
                                                        1st wash of the transplanted area [be gentle], pour warm water on the transplanted area, use baby-shampoo, tapping the shampoo gently with your palm on the transplanted area, NO rubbing or scratching, leave the shampoo for 10 minutes then rinse the shampoo with warm water [using a cup, no shower handle].....[leave it to dry naturally, no hair dryer] then water spray every 30 min.   
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>
                                                        Same as in Day3 but leave the shampoo longer [15 min] before rinsing the area. Water spray every 30 min.
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{'whiteSpace':'nowrap'}}>5 & 6</td>
                                                    <td>
                                                        Same as in Day4 but longer wash [20 min] and move you wet fingers with shampoo gently on the top from back towards front for few minutes before rinsing the area with warm water [leave it to dry naturally, no hair dryer] Water spray every hour.
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{'whiteSpace':'nowrap'}}>7 - 13</td>
                                                    <td>
                                                        Same in Day 5 & 6 but longer wash, double wash, 15 min each, circular movement of your wet fingers and palm [using baby-shampoo] then rinsing thoroughly with warm water [leave it to dry naturally or use clean towel to pad it BUT gently and no friction or any pressure] Water spray every 2 hours.
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>14</td>
                                                    <td>
                                                        Same as in Day 7-13 but longer wash [40 min wash][20 min each] and move circular movement of the your wet fingers and palm with slight more pressure while moving the palm on it then rinsing with warm water using a cup [low pressure water] [keep the water spray bottle in your pocket and use it every 3 hours]
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>After Day 14</td>
                                                    <td>
                                                        Use baby-shampoo alternate with aloe Vera shampoo and Alpacine shampoo for 4-6 months to keep the scalp healthy.
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="medicine">
                    <header>
                        <h3 className="text-center">MEDICATION REGIMEN</h3>
                    </header>
                    <div className="row mt-5">
                        <div className="col-sm-12 col-md-12 mb-5">
                            <div className="card">
                                <ReactPlayer url='https://patient-portal.s3.fr-par.scw.cloud/website/videos/Alosert/medication.mp4' className="card-img-bottom" width='100%' height='450px' controls={true}/>
                                <div className="card-body" ref={ medicationRef }>
                                    <ul>
                                        <li>Take your regular medications for any Diabetes, High blood pressure, Epilepsy or Thyroid conditions but Do NOT take blood thinners for 7 days after the surgery [this must already been mentioned in your written permission letter provided before your hair surgery by your treating doctor, GP or specialist, whoever put you on the blood thinners]</li>
                                        <li>Please inform the hair clinic if you are allergic to penicillin or any other medicines. </li>
                                        <li>For the pain [headache or neck pain or any other pain]: take regular paracetamol every 6 hours for 2 days [Do NOT take aspirin or anti-inflammatory like Nurofen, Ibuprofen, Naproxen, Celebrex…etc]</li>
                                        <li>
                                            The clinic will provide you three types of medications to be taken after the hair surgery
                                            <ul>
                                                <li>Antibiotics for 6 or 7 days [depends on which type][to prevent any possible infection]</li>
                                                <li>Steroid for 3 days [for swelling]</li>
                                                <li>Anti-acid for 7 days.</li>
                                            </ul>
                                        </li>
                                        <li>For itchiness: spray the head with mineral water and anti-histamine for 2 days [no not drive for long distance after taking anti-histamine because you will feel sleepy] BUT do not itch or scratch the scalp.</li>
                                        <li>If you have any concerns, please DO contact the clinic during the opening hours or 111 during the out of hours time.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AlosertPostop
