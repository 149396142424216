export const Birmingham = {
    address: 'Unit 9 The Cloisters, 11-12 George Road, Edgebaston, Birmingham, B15 1NP',
    contact: '0121 389 4898',
    googleSrc: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2430.475190393564!2d-1.9169445482659244!3d52.47053137970567!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4870bdcc086d3423%3A0xf1a6e50c9e07ee11!2sHair%20Growth%20Centre%20l%20Hair%20Transplant%20Birmingham%20UK%20l%20Hair%20Loss%20Clinic%20Birmingham%20UK!5e0!3m2!1sen!2suk!4v1620558549477!5m2!1sen!2suk',
    parking: '',
    bus: '',
    train: ''
};

export const Bromley = {
    address: '38/40 High Street, West Wickham, BR4 0NJ',
    contact: '0208 9022270',
    googleSrc: '',
    parking: '',
    bus: '',
    train: ''
};

export const Kenton = {
    address: '158-160 KENTON ROAD, KENTON, HA3 8AZ',
    contact: '0203 621 2370',
    googleSrc: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2479.181935209664!2d-0.3176207482918828!3d51.58322887954778!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761390d4bd893b%3A0xaa4c3702767f3553!2s158-160%20Kenton%20Rd%2C%20Harrow%20HA3%208AZ!5e0!3m2!1sen!2suk!4v1620557483167!5m2!1sen!2suk',
    parking: `Please be advised patients are advised not to drive on the day of the surgery, and there is no on-site parking available. However, if you choose to drive you can find the closest car parks <a href="https://www.google.com/search?sa=X&rlz=1C1GCEB_enGB908GB908&tbs=lrf:!1m4!1u3!2m2!3m1!1e1!2m1!1e3!3sIAE,lf:1,lf_ui:3&tbm=lcl&q=car%20park%20near%20158-160%20kenton%20road%20ha3%208az&rflfq=1&num=10&ved=2ahUKEwjz-cPJ5JHxAhXVA2MBHdYdD0gQjGp6BAgDEDU&biw=1280&bih=577&rlst=f#rlfi=hd:;si:;mv:[[51.602172599999996,-0.2920178],[51.566599,-0.3557049]];tbs:lrf:!1m4!1u3!2m2!3m1!1e1!2m1!1e3!3sIAE,lf:1,lf_ui:3">here</a>; with the closest one being <a href="https://www.google.co.uk/maps/place/Premier+Inn+London+Harrow+hotel/@51.5828252,-0.317086,19.5z/data=!3m1!5s0x48761397368667fd:0x20ec3f7bf4cecb92!4m10!3m9!1s0x487613ed9deae429:0x68c8bcac1ee6835e!5m2!4m1!1i2!8m2!3d51.5826874!4d-0.3168632!9m1!1b1">Premier Inn car park</a>.`,
    bus: '<a href="https://tfl.gov.uk/plan-a-journey">Please see the TFL transport site.</a>',
    train: 'Kenton Station is a 2 minute walk away and is on the Bakerloo and Overground Lines. Northwick Park station is a 10 minute walk away and is on the Metropolitan Line.'
};
  
export const London = {
    address: '44 Chippenham Road, London, W9 2AF',
    contact: '0208 9022270',
    googleSrc: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d369.0062770226764!2d-0.19792063867235504!3d51.5248016919697!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487604ddd4f83495%3A0xe88ccb3e92038677!2sHair%20Growth%20Centre%20l%20Best%20Hair%20Transplant%20in%20London%20UK%20l%20Hair%20Loss%20Clinic%20London%20UK!5e0!3m2!1sen!2suk!4v1620558665602!5m2!1sen!2suk',
    parking: 'Please be advised patients are advised not to drive on the day of the surgery, and there is no on-site parking available. However, if you choose to drive, there may be on-street parking in the area which will be pay and display meter.',
    bus: 'There are several bus stops close to the surgery including the 18, 28, 31, 36, 328.  Public Transport information is available from the <a href="https://tfl.gov.uk/plan-a-journey/">Traveline website</a>.',
    train: 'The nearest tubes are Maida Vale, Bakerloo line (13 minute walk) or Westbourne Park, Hammersmith and City Line (4 minute Walk)'
};
  
export const Manchester = {
    address: '4th Floor Speakers House, 39 Deansgate, Manchester, M3 2BA',
    contact: '0161 4704504',
    googleSrc: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2374.1684423659635!2d-2.2483174482359582!3d53.48332007990983!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487bb1f0589ff1bf%3A0xc5867514af8fb135!2sHair%20Growth%20Centre!5e0!3m2!1sen!2suk!4v1620558787226!5m2!1sen!2suk',
    parking: 'The nearest car park is Q Park, M3 7FA if you are driving to us on surgery day.',
    bus: '',
    train: ''
};

export const Manchester2 = {
    address: '2nd Floor Speakers House, 39 Deansgate, Manchester, M3 2BA',
    contact: '0161 4704504',
    googleSrc: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2374.1687387288657!2d-2.246382984295406!3d53.48331478439454!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487bb1c4087928cb%3A0xc34c5c3b425ba6c5!2s39%20Deansgate%2C%20Manchester%20M3%202BA!5e0!3m2!1sen!2suk!4v1620836492344!5m2!1sen!2suk',
    parking: 'The nearest car park is Q Park, M3 7FA if you are driving to us on surgery day.',
    bus: '',
    train: ''
}
  
export const Romford = {
    address: '48-50 South Street, Romford, RM1 1RB',
    contact: '01708 572674',
    googleSrc: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2479.5164459440207!2d0.17965315170798046!3d51.57709757954678!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8a562de92105f%3A0xad22784998ededb1!2sHair%20Growth%20Centre%20-%20Romford%20l%20Hair%20Transplant%20Romford%20l%20Hair%20Loss%20Clinic%20Romford!5e0!3m2!1sen!2suk!4v1620558873400!5m2!1sen!2suk',
    parking: 'The nearest Car Park is located in the Brewery Shopping Centre(Pay and Display).',
    bus: '',
    train: 'Nearest train station is the Romford Overground Station (5-10 minute walk)'
};

