import React from 'react';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';

function RafiqPreop() {
    return (
        <div className="container mt-5">
            <header>
                <h3 className="text-center">Pre-op Instructions</h3>
            </header>
            <div className="row mt-5">
                
                <div className="col-sm-12 col-md-12 mb-5">
                    <div className="card">
                        <ReactPlayer url='https://patient-portal.s3.fr-par.scw.cloud/website/videos/Rafiq/rafiq_preop.mp4' className="card-img-bottom" width='100%' height='300px' controls={true}/>
                        <div className="card-body">
                            <p>
                                Most of the following would have already been discussed with your Consultant (Trichologist) with whom you have spoken to prior to arranging the surgery.
                                Below is a list of important things to note prior to your FUE procedure. 
                            </p>
                            <ul className="ditta-preop-instruction-list">
                                <li><b>TIMING</b><br/>FUE (Follicular Unit Extraction) is usually a whole day procedure (lasting approximately 8-9 hours) </li>
                                <li><b>HAIR WASHING</b><br/>You may wash your hair on the Day of Surgery </li>
                                <li><b>HAIRCUT</b><br/>You do not need to cut or shave your hair. This will be done in the clinic on the day of surgery. (This does not apply to the unshaven technique) </li>
                                <li><b>CLOTHING</b><br/>Wear a buttoned or zipped top. Post surgery – clothes should not be taken off over the head due to the risk of damaging the implanted areas. </li>
                                <li><b>MEDICAL CONDITIONS</b><br/>It is important to declare all medical problems that you currently have or have had in the past. Some conditions can impact on the Surgery. Please discuss this with your Consultant beforehand. </li>
                                <li><b>MEDICATION</b><br/>Take your regular medications as prescribed by your Doctor as normal. IF you are taking Aspirin or other blood thinners – these should have been stopped earlier (Please discuss this with your Consultant). These are usually stopped 5-7 days prior to the procedure. </li>
                                <li><b>MULTIVITAMINS</b><br/>Do not use multivitamins & food supplements for at least 2 days before the surgery. </li>
                                <li><b>FOOD/DIET</b><br/>Eat breakfast as normal BUT NO CAFFEINE (tea/coffee/energy drinks) on the day of surgery. </li>
                                <li><b>SMOKING & ALCOHOL</b><br/>Do not smoke or drink for 3 days before the surgery. (This can impact on the outcome of the surgery) </li>
                                <li><b>CONTACT DETAILS</b><br/>Provide your Next of Kin with the Clinic contact details </li>
                                <li><b>DRIVING</b><br/> You are able to drive yourself but it is advisable, if possible, to get dropped off. It is a long procedure which may tire you out at the end of the day. </li>
                                <li><b>WORK ARRANGEMENTS</b><br/>Arrange time off work. At least 7 days. Please discuss this with your Consultant. You may need more time off work (14 days) depending on the nature of your job. </li>
                                <li>
                                    <b>THINGS TO BRING:</b>
                                    <ul>
                                    <li>Appropriate clothing as stated above.  </li>
                                    <li>Headphones – some patients prefer to listen to podcasts or music during the day. Each clinical room has a Wireless Tablet Device on which desired music or podcasts can be played.  </li>
                                    <li>Travel Pillow - if you are staying over in a hotel. Only need following surgery. </li>
                                    <li>Relevant paperwork – your documents provided by your Consultant/Trichologist </li>
                                    </ul>
                                </li>
                                <li><b>Consent Form</b><br/>You will be asked to sign a consent form on your surgery day. Please have a read through the consent form as it will speed up the process during your surgery date. <Link to="/patient-consent-form" className=''>Click me to view the consent form.</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RafiqPreop
