import React from 'react';
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import '../../css/Doctors.css';

function DrShuaib() {
    return (
        <div className="container doctor-profile-page my-5">
            <h2 className="doctor-profile-page-name">Dr Shuaib Mir</h2>
            <img src="https://patient-portal.s3.fr-par.scw.cloud/website/images/Doctors/DrShuaibc.png" alt="Dr Shuaib" className="float-sm-end" style={{height:'300px'}} />
            <ul className="doctor-list-style-none p-0">
                <li><IoMdCheckmarkCircleOutline size="20px" color="#191667" />  Dr Shuaib is a highly experienced Hair Transplant Surgeon with more than 3 years of experience and over 1500 successful cases in the hair restoration industry.</li>
                <li><IoMdCheckmarkCircleOutline size="20px" color="#191667" />  Studying abroad, he has learnt to integrate with people from all walks of life, improving his versatility and interpersonal skills. He has worked in various surgical specialities and undertaken multiple surgical courses in plastics (BAAPS), anatomy (RCS) and trauma & orthopaedics (ATLS). His background in Emergency medicine has provided him with years of hands-on experience dealing with a variety of medical procedures and dermatological conditions.</li>
                <li><IoMdCheckmarkCircleOutline size="20px" color="#191667" />  He has undergone extensive training in FUE hair transplantation, PDT lasers and various other medical procedures for hair restoration including PRP and mesotherapy.</li>
                <li><IoMdCheckmarkCircleOutline size="20px" color="#191667" />  Dr Shuaib specialises in both male and female transplantation, Afro-Caribbean hair, beard and eyebrow transplants. He is also highly skilled in using his unique artistic skill to precisely design hairlines for patients of various demographics.</li>
            </ul>
        </div>
    );
}

export default DrShuaib
