import './App.css';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import Home from './pages/Home';
import Preop from './pages/Preop';
import Postop from './pages/Postop';
import Login from './pages/Login';
import Logout from './components/Logout';
import Doctor from './pages/Doctor';
import Faq from './pages/Faq';
import Health from './pages/Health';
import ConsentForm from './pages/ConsentForm';

function App() {

	return (
		<div className="App">
			<Router>
				<Switch>
					<ProtectedRoute path="/" exact component={Home} />
					<ProtectedRoute path="/preop" component={Preop} />
					<ProtectedRoute path="/postop" component={Postop} />
					<ProtectedRoute path="/doctor" component={Doctor} />
					<ProtectedRoute path="/faq" component={Faq} />
					<ProtectedRoute path="/health" component={Health} />
					<Route path="/login" component={Login} />
					<Route path="/logout" component={Logout} />
					<Route path='/patient-consent-form' component={ConsentForm} />
				</Switch>
			</Router>
		</div>
	);
}

export default App;
