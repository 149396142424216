import React from 'react';
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import '../../css/Doctors.css';

function DrRafiq() {
    return (
        <div className="container doctor-profile-page my-5 pb-5">
            <h2 className="doctor-profile-page-name text-nowrap">Dr Zakar Rafiq</h2>
            <img src="https://patient-portal.s3.fr-par.scw.cloud/website/images/Doctors/DrR.png" alt="Dr Ditta" className="float-sm-end" style={{height:'300px'}} />
            <ul className="doctor-list-style-none p-0">
                <li><IoMdCheckmarkCircleOutline size="20px" color="#191667" />  Dr. Zakar Rafiq has been in the hair restoration industry for 7 years and has performed over 4000 surgeries making him one of the most experienced surgeons in the UK.</li>
                <li><IoMdCheckmarkCircleOutline size="20px" color="#191667" />  UK born and bred.</li>
                <li><IoMdCheckmarkCircleOutline size="20px" color="#191667" />  Completed his MBBS and BSc (Hons) at Imperial College London (14th in World League Standing), and Surgical Training completed at the prestigious Surgical Academic Unit in St Mary’s Hospital, Paddington. Completed other surgical specialities including, General, Colorectal, Breast and Endocrine, Trauma and Orthopaedics and Neurosurgery.</li>
                <li><IoMdCheckmarkCircleOutline size="20px" color="#191667" />  His practice now is dedicated to the treatment of hair loss and alopecia in men and women. Highly qualified having performed over 4000 successful surgeries.</li>
                <li><IoMdCheckmarkCircleOutline size="20px" color="#191667" />  Member of the International Society of Hair Restorative Surgery. Has led conferences internationally and trained many other surgeons on the art of hair transplant procedures.</li>
                <li><IoMdCheckmarkCircleOutline size="20px" color="#191667" />  Clinical interest in Hairline Restoration and FUE Hair Transplantation.</li>
                <li><IoMdCheckmarkCircleOutline size="20px" color="#191667" />  Ringside Doctor in Amateur British Boxing Board of Control boxing bouts , volunteering as a St Johns Ambulance Doctor and is a Medicolegal Medical Expert.</li>
            </ul>
        </div>
    );
}

export default DrRafiq
